.settings_area_con_inner {
    max-width: 766px;
    margin: 0 auto;
    padding: 0px 0 25px;
}

.settings_area_con_inner .box {
    margin-bottom: 1.33333333em;
    background-color: #FFFFFF;
    background-color: #111;
    border: 1px solid #E1E7EE;
    border: 1px solid #424242;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.5);
    padding: 1.33em;
    text-align: left;
    color: white;
}
.settings_area_con_inner h1 {
    color: white;
}
.settings_area_con_inner .box label{
    color: white;
}