.add_custom_domain_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 15px;
}

.add_custom_domain_row button,
.add_custom_domain_row span {
    font-size: 12px;
}

.ml-0 {
    margin-left: 0;
}
.pl-0 {
    padding-left: 0px;
}