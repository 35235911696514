.last_switches_row textarea {
    width: 100%;
    height: 150px;
    border-radius: 4px;
    background-color: #f6f7f9;
    border: 1px solid #ccc;
    padding: 10px;
}
.last_switches_row {
    flex-direction: column;
}

.last_switches_row p {
    padding: 10px !important;
}
.last_switches_row p.one {
    padding: 0px !important;
}

.success_msg_con {
    margin: 20px 0;
}
.success_msg_con div,
.success_msg_con p {
    width: 100%;
}
.add_affiliate_network_dialogue_content .k-multiselect-wrap{
    background: transparent;
    border: 1px solid #c3cfde !important;
}
