.homepage #jobs *{
    font-family: 'Montserrat', sans-serif;
}
.homepage .header{
    display: block;
    background-color: #000000;
    height: 600px;
    width: 100%;
    height: 100%;
    background-color:#18181b ;
    height: 100vh;
    overflow: auto;
    overflow: hidden;
    height: min-content;
    /*padding-bottom: 30px;*/
    padding-top: 157px;
}
.homepage section {
  width: 100%;
  padding: 100px 0;
}
.homepage{


}
.homepage .header .header-content {
    /*padding-top: 160px;*/
    width: 100%;
    color: white;
    flex-wrap: wrap;
    display:flex;
    justify-content: center;

    /*padding: 0px 55px;*/
    
    /*padding-top: 102px;*/
    

}
.homepage .header .header-content p {
    font-size: 20px;    
    text-align: center !important;
    
}
.homepage .header .header-content h1 ,.homepage .title {
    /*padding-right: 255px;*/
    text-align: left;
    font-size: 46px;
    text-align: center ;
    font-size: 56px;

}
.homepage .header .header-content .btns {
    margin-top: 40px;

    display: flex;
    /*flex-wrap: wrap;*/
    margin-top: 6px;

    justify-content: center;

}
.homepage .header .header-content  #Request-btn{
    border-color: #797979;
    margin: 0 10px;
    background-color: transparent;
    padding: 15px 25px;
    color: #fff;
    font-size: 14px;
    width: 157px;
    border: 1px solid;
    border-radius: 25px;
    background: #e0358b;
    color: #e9f4fb;
    border-color: #e0358b;
    background-color:transparent;
    border-color: #797979;
    margin-bottom: 15px;
    margin-left: 0px;
}

.homepage .header .header-content .btns #sign-up-btn{
    width: 157px;
    margin: 0 10px;
    background: #e0358b;

    color: #fff;
    padding: 15px 25px;
    font-size: 14px;
    border: 1px solid #e0358b;
    border-radius: 25px;
    height: min-content;
}
.homepage .header .header-content p {
    font-size: 20px;
    padding: 0;
    line-height: 1.2;
    font-size: 22px;
    font-size: 26px;

    /*font-size: 24px;*/
}




.homepage h2 {
    font-weight: 700;
    font-size: 46px;
    line-height: 60px;
    margin-bottom: 20px;
    letter-spacing: -0.03em;
    font-size: 53px;
  }
  .homepage h4 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  
  .homepage footer .inner .right .mail_to_btn:hover {
    background: #ba2374;
    text-decoration: underline;
    transition: 0.3s ease;
  }
  
  .homepage footer {background-color: #111111;}
  .homepage footer .inner {
    display: flex;
    max-width: 1024px;
    width: 100%;
    margin: 0 auto;
    color: #fff;
    justify-content: space-between;
    padding: 60px 0;
    align-items: center;
  }
  .homepage footer .inner .left {
    color: rgba(255,255,255,.5);
  }
  .homepage footer .inner .left {}
  .homepage footer .inner .left,
  .homepage footer .inner .right {
    text-align: center;
  }
  .homepage footer .inner .left h2 {
    font-weight: 300;
    color: rgba(255,255,255,.5);
    margin: 0 0 5px 0;
    font-size: 53px;
    line-height: 44px;
  }
  .homepage footer .inner .left h4 {
    font-size: 16px;
    font-weight: 300;
  }
  .homepage footer .inner .right .mail_to_btn {
    display: block;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 15px;
    background: #e0358b;
    border-radius: 5px;
    color: #fff;
    margin-bottom: 15px;
    cursor: pointer;
  }
  .homepage footer .inner .right .social_links {
    color: #fff;
    font-size: 18px;
    margin: 0 10px;
  }
  .homepage footer .copy {
    background: #000;
    padding: 15px;
    color: #fff;
    text-align: center;
    border-top: 1px solid #797979;
  }
  .homepage .copy{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 13px;
    align-items: center;
  }
  .homepage .copy a{
    margin-left: 5px;
    color: #e0358b;
    cursor: pointer;


  }
  .homepage .copy a:hover{

    color: #ba2374;

  }
  .homepage footer .inner .left h4{
    margin: 10px 0px;
  }

  @media(min-width:769px){
    .header-content{
      padding-left: 10px;
    }
  }
    @media only screen and (max-width: 768px) {
      .homepage  footer {
      padding-left: 15px;
      padding-right: 15px;
    }
    .homepage footer .inner {
      flex-direction: column;
    }
    .homepage section {
      padding: 45px 15px;
    }
  }
  
  
  @media (max-width:768px){
    .homepage footer .inner .right{
       margin-top: 5px;
    }
    .homepage footer .inner .right .social-icons{
      margin-top:5px;
    }
    .homepage footer .inner{
      padding: 20px 0;
    }
    .homepage section {
      padding: 45px 15px;
    }
    .homepage .header{
      padding-top: 103px;
      padding-top: 68px;
    }

    
  }
  @media (max-width:346px){
    .homepage footer .inner .right .social-icons .social_links{
      margin: 0px 5px;
    }
  }
  @media (max-width:279px){
    .homepage footer .inner .right .social-icons .social_links{
      margin: 0px 3px;
      font-size: 15px;
    }
  }
  @media (max-width: 320px) {
    .homepage footer .inner .right .social-icons .social_links {
      margin: 0px 7px;
      font-size: 18px;
    }
  }
  
  @media (max-width:300px){
    .homepage footer .inner .right .social-icons .social_links{
      margin: 0px 3px;
      font-size: 15px;
    }
  }
  @media (max-width:320px){
    .homepage footer .inner .left h2{
      font-size: 38px;
    }
    .homepage footer .inner .left h4{
      font-size: 14px;
    }
  }
  @media (max-width:467px){
  .homepage .copy a{
    margin-top: 5px;


  }}



  /*careers*/
  .homepage .transition_small {
    transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    -webkit-transition: all 0.6s ease;
  }
  
  .homepage section {
    width: 100%;
    /*padding: 100px 0;*/
  }
  
  .homepage section .inner {
    max-width: 1024px;
    margin: 0 auto;
  }
  
  .homepage #jobs {
    background-color: #000;
    color: #fff;
    text-align: left;
    font-family: 'Montserrat', sans-serif !important;
  }
  
  .homepage h2 {
    font-weight: 700;
    font-size: 46px;
    line-height: 60px;
    margin-bottom: 20px;
    letter-spacing: -0.03em;
  }
  
  .homepage p, .homepage ul .homepage li {
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 12px;
  }
  .homepage h4 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .homepage .panel-group .panel {
    overflow: hidden;
  }
  .homepage .panel
{
    background-color: rgba(0,0,0,0) !important;
  }
  .homepage .panel-heading {
    background-color: #333 ;
  }
  .homepage .panel-default {
    border: 0px;
  }
  .homepage .panel-default>.panel-heading,
  .homepage .panel-heading {
    border-width: 0px 0px 1px 0px;
    border-style: solid;
    border-color: #000;
    color: #fff;
  }
  
  .homepage .panel-heading:hover {
    /*background-color: #1e76bd !important;*/
    color: #fff;
    /*background: #e0358b !important;*/
  }
  .homepage .panel-heading:hover .panel-default {
    border-color: #1e76bd !important;
  }
  
  .homepage .panel-body {
    padding: 35px;
    background-color: #fff;
    color: #000;
  }
  
  .homepage .jobs_desc {}
  .homepage .jobs_desc p {
    color: #000;
  }
  .homepage .jobs_desc .job_res ul {
    padding-left: 25px;
  }
  .homepage .jobs_desc .job_res ul li {
    list-style: disc;
    margin-bottom: 5px;
  }
  
  .homepage .email_us_sec {
    padding: 20px 0;
  }
  .homepage .email_us_sec a {
    font-size: 16px;
      line-height: 20px;
      padding: 10px 15px;
      background: #337ab7;
      border-radius: 5px;
      color: #fff;
      cursor: pointer;
      display: inline-block;
      background: #e0358b;
      transition: 0.3s ease;
  }
  
  .homepage .email_us_sec a:hover {
    background: #255681;
    background: #ba2374;
  }
  .homepage .panel-heading:hover {
    /*text-decoration: underline;*/
  }

  .homepage .panel-collapse{
    transition: 0.5s ease;
  }

  .homepage .panel-title div {
    font-size: 16px;
  }
  .homepage .panel-title{
    margin-bottom: 0px;
  }
  
  html {
    scroll-behavior: smooth;
  }
    @media only screen and (max-width: 768px) {
      .homepage section {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  
  
  
  @media (max-width:768px){
    .homepage section{
      padding: 45px 15px;
      padding-top: 15px;
    }
  }
  

  .homepage .db-img{
    width: 830px;
    width: 939px;
    width: 880px;
    width: 968px;
  }

  .header-content-right{
    width: 45%;
    /*text-align: center;*/
    /*margin-top: 40px;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 102px;

    padding: 0;

  }

  .header-content-right *{
    text-align:center ;
  }
  .homepage .header .header-content #mobile-btns{
    display: none;
  }


  .homepage #jobs * {
    text-align: left;
  } 
  .homepage #jobs .inner h2 {
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    font-size: 56px;
    text-align: left;
    font-weight: 500;
  
  }

  .homepage #jobs .panel-title{
    text-align: center;
  }
  .homepage #jobs .email_us_sec{
    text-align: left;
  }

  @media(max-width:1769px){
    .homepage .db-img{
      width: 665px;
      width: 690px;
      width:715px;
      width: 787px;
    }
    .homepage .header .header-content h1,.homepage .title{
      font-size: 40px;
      font-size: 56px;
    }
    .homepage #jobs .inner h2{
      font-size: 56px;
    }
    .homepage .header .header-content p{
      font-size: 19px;
      font-size: 26px;
      padding-left: 10px;
    }
    .homepage .header .header-content{
      /*padding: 0px;
      padding-top: 102px;*/
    }
  }
  @media(max-width:1440px){
    .homepage .header .header-content h1,.homepage .title{
      font-size: 29px;
      font-size: 40px;
    }
    .homepage #jobs .inner h2{
      font-size: 40px;
    }
    .homepage .header .header-content p{
      font-size: 15px;
      font-size: 18px;
    }
    .homepage .db-img{
      width: 457px;
      width: 507px;
      width: 558px;
      width: 557px;
      width: 667px;
    }
  }
@media(max-width:1222px){
  .homepage .header .header-content h1,.homepage .title{
    font-size: 29px;
    font-size: 40px;
  }
  .homepage #jobs .inner h2{
    font-size: 40px;
  }
  .homepage .header .header-content p{
    font-size: 15px;
    font-size: 18px;
  }
  .homepage .db-img{
    width: 457px;
    width: 507px;
    width: 558px;
    width: 557px;
  }
}
@media(max-width:1024px){
  .homepage .header .header-content h1,.homepage .title{
    font-size: 22px;
    font-size: 30px;
  }
  .homepage #jobs .inner h2{
    font-size: 30px;
  }
  .homepage .header .header-content p{
    font-size: 15px;
  }
  .homepage .db-img{
    width: 329px;
    width:379px;
    width: 417px;

  }
  .homepage .header .header-content .btns #sign-up-btn{
    width: unset;
    padding: 13px 23px;
    font-size: 11px;
    margin: 4px;
  }
  .homepage .header .header-content #Request-btn{
    width: unset;
    padding: 13px 23px;
    font-size: 11px;
    margin: 4px;
  }
}
@media(max-width:768px){
  .homepage .header .header-content{
    justify-content: center;
    /*padding: 54px;*/
    /*padding-top: 70px;*/
  }
  .header-content-right{
    width: 100%;
  }
  .homepage .header .header-content h1,.homepage .title{
    font-size: 35px;
    font-weight: bolder;
    font-size: 47px;
  }
  .homepage #jobs .inner h2{
    font-size: 47px;
    font-weight: bolder;
  }
  .homepage .header .header-content p{
    font-size: 17px;
    margin: 0px;
    font-size: 19px;
  }
  .homepage .db-img{
    width: 520px;
    margin-top: 24px;
    width: 618px;
  }
  .homepage .header .header-content .btns #sign-up-btn{
    width: unset;
    padding: 13px 23px;
    font-size: 13px;
    margin: 4px;
    width: 300px;
  }
  .homepage .header .header-content #Request-btn{
    width: unset;
    padding: 13px 23px;
    font-size: 13px;
    margin: 4px;
    width: 300px;
  }
  .homepage .header .header-content .btns {
    margin-top: 24px;
  }
  .homepage .header .header-content #desktop-btns{
    display: none;
  }
  .homepage .header .header-content #mobile-btns{
    display: flex;
  }

}
@media(max-width:690px){
  .homepage .header .header-content h1,.homepage .title{
    font-size: 31px;
    font-size: 40px;
  }
  .homepage #jobs .inner h2{
    font-size: 40px;
  }
  .homepage .header .header-content p{
    font-size: 15px;
    font-size: 17px;
  }
  .homepage .db-img{
    width: 460px;
    width: 522px;
  }
  .homepage .header .header-content .btns #sign-up-btn{
    padding: 13px 23px;
    font-size: 12px;
    width: 235px;
  }
  .homepage .header .header-content #Request-btn{
    padding: 13px 23px;
    font-size: 12px;
    width: 250px;
  }
  /*.homepage .header .header-content{
    padding: 0px 10px;
    padding-top: 70px;
    
  }*/
}

@media(max-width:540px){
  .homepage .header .header-content h1,.homepage .title{
    font-size: 24px;
    font-size: 33px;
  }
  .homepage #jobs .inner h2{
    font-size: 33px;
  }
  .homepage .header .header-content p{
    font-size: 12px;
    font-size: 16px;
  }
  .homepage .db-img{
    width: 360px;
    width: 413px;
  }
  .homepage .header .header-content .btns #sign-up-btn{
    padding: 13px 23px;
    font-size: 12px;
    width: 190px;
  }
  .homepage .header .header-content #Request-btn{
    padding: 13px 23px;
    font-size: 12px;
    width: 190px;
  }
}
@media(max-width:425px){
  /*.homepage .header .header-content{
    padding:0 5px ;
    padding-top: 54px;
    padding-top: 70px;
  }*/
  .homepage .header .header-content h1,.homepage .title{
    font-size: 21px;
    font-size: 29px;
    margin-bottom: 10px;
  }
  .homepage #jobs .inner h2{
    font-size: 29px;
  }
  .homepage .header .header-content p{
    font-size: 11px;

    font-size: 12px;
    line-height: 1.6;
  }
  .homepage .db-img{
    width: 340px;
    width: 366px;
  }
  .homepage .header .header-content .btns #sign-up-btn{
    padding: 13px 33px;
    font-size: 10px;
    font-size: 14px;
    width: 176px;
  }
  .homepage .header .header-content #Request-btn{
    padding: 13px 33px;
    font-size: 10px;
    font-size: 14px;
    width: 176px;

  }
  .homepage .header .header-content p{
    font-size: 16px;
    text-align: center !important;
    /*padding-left: 5px;*/

  }
  .homepage .header .header-content h1,.homepage .title{
    text-align: center;
    /*padding-left: 5px;*/
  }
  .header-hero-text br{
    display: none;

  }
  .homepage .header .header-content .btns #sign-up-btn{
    padding: 13px 33px;
    font-size: 10px;
    font-size: 11px;
    width: 150px;
  }
  .homepage .header .header-content #Request-btn{
    padding: 13px 33px;
    font-size: 10px;
    font-size: 11px;
    width: 150px;

  }
}
@media(max-width:375px){

  .homepage .header .header-content h1,.homepage .title{
    font-size: 18px;
    font-size: 25px;
  }
  .homepage #jobs .inner h2{
    font-size: 25px;
  }
  .homepage .header .header-content p{
    font-size: 15px;
  }
  .homepage .db-img{
    width: 300px;
  }
  }

@media(max-width:315px){
  .homepage .header .header-content #mobile-btns{
    flex-wrap: wrap;
  }
  .homepage .db-img{
    width: 270px;
  }
}
@keyframes image-1{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
@keyframes image-2{
  0%{
    translate: 1000px;
  }
  100%{
    translate: 0px;
  }
}

.homepage .pricing{
  width: 100%;
  height: 500px;
  color: white;
  background: #18181B;
}
.homepage .pricing.title{
  color: white;
}


.features-section {
  background: #000;
}

.homepage .header .header-content .btns #sign-up-btn:hover{
  transition: 0.3s ease;
  background: #ba2374;
}
.homepage .desc {
  display: none;
}
.features-page .features ul.feature-list li.active-box .item .desc {
  display: block;
}
.homepage .careershover{
  background-color: #e0358b !important;
  text-decoration: underline;
}

@media (hover: hover) and (pointer: fine) {
  .homepage .panel-heading:hover {
  background-color: #e0358b ;
  text-decoration: underline;
}
}
/*@media (hover: none) {
  .homepage .panel-heading:hover {
  animation: mobilehover 1s ease;
}

}

@keyframes mobilehover{
  0%{
    background-color:#333 ! ;
  }
  50% {
    background-color: #e0358b ;
  }
  100%{background-color: #333 ;}
}*/
@media (max-width:991px) and (min-width:848px){
  .pricing-section .features-page .home-container-desk{
    width:864px;
  }
}

.homepage a:focus, .homepage a:hover {
  text-decoration: none;
  color: none;
}