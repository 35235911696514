.header-first-row {
  padding: 7px 5px;
  display: flex;
  align-items: center;
  background: white;
  background: unset;
  justify-content: flex-end;
}

.search-box {
  padding: 6px 12px;
  width: 300px;
  display: inline-block;
  margin: 0 8px;
  background: transparent;
}

.menu-buttons {
  background: var(--color_success);
  color: var(--white);
  border-radius: 4px;
  margin-left: 10px;
  font-size: 12px;
  line-height: 29px;
  height: 29px;
  background: #e0358b;
  border-color: #e0358b;
}
.menu-buttons:hover{
  background: #e0358b;
  border-color: #e0358b;
}