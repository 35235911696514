.add-width{
    box-sizing: border-box;
    margin-left: 1.33333333em;
    min-width: 0em;
    padding-top: 0em;
    padding-right: 0.66666667em;
    padding-bottom: 0em;
    padding-left: 1em;
    line-height: 2.33333333em;
    border-radius: 0.25em;
}

.hr{
    box-sizing: border-box;
    margin: 0.66666667em 0em 1.33333333em 0em;
    border-width: 1px 0px 0px;
    border-color: #F0F3F7;
    border-style: solid;
}

.filtering-label{
    display: block !important;
    font-weight: 500 !important;
    line-height: 1.25rem !important;
    font-size: 1rem !important;
    box-sizing: border-box !important;
    margin-bottom: 0em !important;
    color: black !important;
}

.filter-textarea{
    border-width: 1px;
    box-sizing: border-box;
    padding: 7px;
	resize: none;
	background-color: #fff;
    background-color: #202024;

    border: 1px solid #c3cfde;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 400;
    height: 100px;
    line-height: 16px;
    outline: none;
	width: 50em;
    color: white;
}
.filter-textarea:focus{
    border-color: #e0358b;
}

.filtering-modal-content Input{
    width: 43em;
}