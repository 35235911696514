.menu_charts_container {
    padding: 5px 0px;
}
.menu_charts_container svg  g text[dominant-baseline="central"] {
    fill: white !important;
}
.menu_charts_container svg  g text[text-anchor="middle"]{
    fill: white !important;
}

@media (max-width:600px){
    .dashboard-chart svg[xmlns="http://www.w3.org/2000/svg"] text{
        font-size: 8px !important; 
    }
    svg[xmlns="http://www.w3.org/2000/svg"] text{
        font-size: 8px !important; 
    }
}
