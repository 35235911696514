.condition-modal .k-dialog{
    width: 640px !important ;
    height: auto ;
    min-height: auto;
    max-height: fit-content;
    position: absolute;
    top: 200px;
    /* left: px; */
    /* width: 640px !important; */
    border-radius: 5px ;
    /* max-width: none; */
    visibility: visible;
    padding: 10px;
    padding-bottom: 0px;
}
/* asdfdsaf */
.condition-modal .k-dialog-content{
    padding: 0px !important;
}

.conditions-select{
    padding: 10px;
    background: #18181b;
}

.k-switch .k-switch-container .k-switch-label-on,
.k-switch .k-switch-container .k-switch-label-off {
    visibility: visible !important;
    font-size: 0.75rem;
    top: 10px;
}

.conditions-select .k-switch .k-switch-container {
    width: 18.67em;
    left: 0px;
    height: 20px;
    background-color: #DAF6E6;
}

.conditions-select .k-switch .k-switch-container .k-switch-label-on{
    left: 60px;
    right:0px;
    width: 100px;
}

.conditions-select .k-switch .k-switch-container .k-switch-label-off{
    left: 60px;
    width: 100px;
}

.conditions-select .k-switch-on .k-switch-container {
    color: #29AF61;
}
.conditions-select .k-switch-off .k-switch-container {
    background-color: #FEE6EB !important;
    color: #F73A5D;
    
}

#conditions-switch .k-switch-handle{
    width: 28px;
    height: 28px;
}

.k-animation-container {
    z-index: 10003;
}


.conditions-search{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
    min-height: 18.33333333em;
    margin-bottom: 1em;
    height: 18.333333em;
}
.conditions-search .parent-items{
    margin: 0;
    box-sizing: border-box;
    height: 18.33333333em;
    display: flex;
    flex: 1;
    flex-direction: column;
    border-width: 1px;
    margin-bottom: 1em;
    border-radius: 0.41666667em;
    border-style: solid;
    border-color: #C3CFDE;
    overflow: hidden;
    height: 100%;
    padding: 0;
    max-width: 220px;
    margin-right: 0.66666667em;
    
}
.conditions-search .parent-items .input{
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 5px;
    padding-left: 30px;
    border-width: 1px;
    height: 1.9em;
    border-radius: 0.25em;
    border-style: solid;
    border-color: #C3CFDE;
    background-color: #FFFFFF;
    box-sizing: border-box;
    margin: 0.66666667em;
    width: auto;
    background: transparent;
    color:white;
}
.conditions-search .parent-items .input:hover:focus{
    border-color: #e0358b;
    transition: 0.5s ease;
}

.conditions-search .parent-items .input:focus, .conditions-multiselect .k-multiselect-wrap:focus{
    border-color: #6786de;
}

/* .conditions-multiselect .k-multiselect .k-multiselect-wrap:focus{
    border-color: #6786de !important;
}
.conditions-multiselect .k-multiselect-wrap .k-floatwrap{
    border-color: brown !important;
 
} */

.conditions-search .parent-items .k-i-search{
    top: -33px;
    left: 16px;
    color: #A6ADBF;
}

.conditions-search .tag-list{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #E1E7EE;
}

.conditions-search .parent-items .k-i-track-changes-accept-all{
    box-sizing: border-box;
    font-size: 6em;
    padding-bottom: 20px;
    /* height: 6.66666667em; */
    color: #E1E7EE;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
}

.condition-checkbox{
    padding-left: 10px;
    color: #ffffffb8;
}
.conditions-select .k-multiselect-wrap{
    background: transparent;
    color: white;
}
.conditions-select .k-widget{
    color: white;
    background: transparent;
}
.conditions-select .k-select{
    background: transparent;
}
.conditions-select .k-select:hover{
    background: rgba(255, 255, 255, 0.048);
}
.condtions_timezone{
    color: white;
}
.condtions_timezone select{
    color: white;
    background: transparent;
}
.condtions_timezone select option{
    color: white;
    background: #121214;
}