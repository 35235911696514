.rdrStaticRangeLabel {
  padding: 5px 20px;
}

.time_picker_con {
  display: flex;
  justify-content: space-evenly;
  padding: 2px;
  line-height: 18px;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
}

.time_picker_con .inner span {
  margin-right: 5px;
}

.time_picker_con .inner select {
  border-radius: 3px;
}

.time_picker_con .inner select:active,
.time_picker_con .inner select:hover,
.time_picker_con .inner select:focus {
  border: 1px solid var(--theme_blue_color);
}

.calendar_bottom_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 5px 5px;
  width: 88%;
}

.calendar_bottom_row select {
  max-width: 300px;
  height: 31px;
  border-radius: 5px;
}
.cancelBtn {
  padding: 5px 10px;
  cursor: pointer;
  display: inline-block;
  background: white;
  border-radius: 4px;
  border: 1px solid grey;
}
.calendarCell[style="border-radius: 0px; color: black;"]{
  color: white !important;
}
.calendarCell[style="border-radius: 4px; color: rgb(153, 153, 153); background-color: rgb(255, 255, 255); cursor: pointer; opacity: 0.25;"]{
  color: black !important;
  
  color: white !important;
  background: transparent !important;
  opacity: 1 !important;
}
.calendarCell[style="border-radius: 0px; color: black; outline: cornflowerblue auto;"]{
  color: white !important;

}
.calendarGrid div[style="border-radius: 0px; color: rgb(0, 0, 0); background-color: rgb(235, 244, 248); cursor: pointer;"]{
  color: white !important;
  background: #202024 !important;
  background: #222 !important;
  background: #27272b !important;
}
.calendarGrid div:hover{
  color: white !important;
  background: #34343a !important;
}
.calendarCell[style="border-radius: 4px 0px 0px 4px; color: rgb(255, 255, 255); background-color: rgb(53, 122, 189); cursor: pointer;"]{
  color: white !important;
  background-color: black !important;
  border: 0px !important;
  outline: 0px !important;
}
.calendarCell[style="border-radius: 0px 4px 4px 0px; color: rgb(255, 255, 255); background-color: rgb(53, 122, 189); cursor: pointer;"]{
  color: white !important;
  background-color: black !important;
  border: 0px !important;
  outline: 0px !important;
}
.calendarCell[style="border-radius: 4px 0px 0px 4px; color: rgb(255, 255, 255); background-color: rgb(53, 122, 189); cursor: pointer; outline: cornflowerblue auto;"]{
  color: white !important;
  background-color: black !important;
  border: 0px !important;
  outline: 0px !important;
}

.calendarCell[style="border-radius: 0px 4px 4px 0px; color: rgb(255, 255, 255); background-color: rgb(53, 122, 189); cursor: pointer; outline: cornflowerblue auto;"]{
  color: white !important;
  background-color: black !important;
  border: 0px !important;
  outline: 0px !important;
}
.calendarCell[style="border-radius: 4px; color: rgb(153, 153, 153); background-color: rgb(255, 255, 255); cursor: pointer; outline: cornflowerblue auto; opacity: 0.25;"]{
  color: white !important;
  background-color: black !important;
  border: 0px !important;
  outline: 0px !important;
}
.calendarCell{
  border: 0px !important;
  outline: 0px !important;
}

.rangecontainer div[style="color: rgb(245, 245, 245); font-size: 13px; background-color: rgb(0, 136, 204); border: 1px solid rgb(245, 245, 245); border-radius: 4px; cursor: pointer; margin: 4px 4px 8px;"]{
  background: black !important;
  color: #e0358b !important;
  border: 0px !important;
}
.rangecontainer div[style="color: rgb(245, 245, 245); font-size: 13px; background-color: rgb(0, 136, 204); border: 1px solid rgb(245, 245, 245); border-radius: 4px; cursor: pointer; margin: 4px 4px 8px; outline: cornflowerblue auto;"]{
  background: black !important;
  color: #e0358b !important;
  border: 0px !important;
  outline: 0px !important;
}
.rangecontainer div[style="color: rgb(245, 245, 245); font-size: 13px; border: 1px solid rgb(245, 245, 245); border-radius: 4px; cursor: pointer; margin: 4px 4px 8px; background-color: rgb(0, 136, 204); outline: cornflowerblue auto;"]{
  background: black !important;
  color: #e0358b !important;
  border: 0px !important;
  outline: 0px !important;
}
.rangecontainer div[style="color: rgb(245, 245, 245); font-size: 13px; border: 1px solid rgb(245, 245, 245); border-radius: 4px; cursor: pointer; margin: 4px 4px 8px; background-color: rgb(0, 136, 204);"]{
  background: black !important;
  color: #e0358b !important;
  border: 0px !important;
}