.settings_area_container {
    height: 100%;

    overflow: scroll;
    overflow: auto;
}
.white_box_with_header_con {
    border: 1px solid #E1E7EE;
    border: 1px solid #6f6c6c;
    margin-bottom: 25px;
    background-color: #111;
}

.white_box_with_header_con .header {
    padding-left: 22px;
    padding-right: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    background:#080808;
    border-color: #5B5859;
}

.white_box_with_header_con .box {
    background: #fff;
    background: #111;
    padding-left: 22px;
    padding-right: 22px;
    color: white;
}
.white_box_with_header_con .header .title_con {
    display: flex;
    align-items: center;
}
.white_box_with_header_con .header h2 {
    margin-right: 10px;
    color: white;
}
.white_box_with_header_con .box .border_bottom {
    /*border-bottom: 1px solid var(--light_grey_border_color);*/
}
.white_box_with_header_con .box section {
    padding-bottom: 15px;
}
.white_box_with_header_con .box .settings_table_con {}
.white_box_with_header_con .box .settings_table_con .settings_table {
    width: 100%;
    border-spacing: 0px;
    border-width: 1px 0px 1px 1px;
    border-style: solid;
    border-color: #5B5859;
    border-width: 1px 1px 1px 1px;
}
.white_box_with_header_con .box .settings_table_con .settings_table tr {}
.white_box_with_header_con .box .settings_table_con .settings_table tr:nth-child(even){
    background-color: #f6f7f9;
    background-color: transparent;
}
.white_box_with_header_con .box .settings_table_con .settings_table tr:nth-child(even):hover{
    background-color: transparent;
}

.white_box_with_header_con .box .settings_table_con .settings_table tr th {
    background-color: var(--settings_table_th_bg_color);
    background-color: #e0358b;
    border-color: #424242;
    color: var(--white);
    font-weight: 400;
}
.white_box_with_header_con .box .settings_table_con .settings_table tr th,
.white_box_with_header_con .box .settings_table_con .settings_table tr td {
    padding: 0.6em;
    border-width: 0px 1px 0px 0px;
    border-style: solid;
    border-color: #5B5859;
    font-size: 12px;
    line-height: 15px;
}

.white_box_with_header_con .box .settings_table_con .settings_table tr:first-child  th{
    border-bottom: 2px solid #5B5859 ;
}
.white_box_with_header_con .box .settings_table_con .settings_table tr:first-child  th:last-child{
    border-right:0px ;
}
.white_box_with_header_con .box .settings_table_con .settings_table tr td .icons span {
    margin: 0 5px;
}
.white_box_with_header_con .box .settings_table_con .settings_table tr td {
    cursor: pointer;
}
.white_box_with_header_con .box .settings_table_con .settings_table tr:hover {
     background-color: #E8EFFD;
 }

.settings_radio_inputs {
    position: relative;
    top: 1px;
    width: 11px;
    height: 11px;
    margin-right: 7px;
}

.half_width { min-width: 50%; }

.settings_dialogue .k-dialog {
    min-width: 700px;
    min-height: 330px;
    border-radius: 8px;
}
.settings_dialogue .k-dialog-titlebar {
    background: var(--white);
    color: var(--theme_blue_color);
    border-radius: 8px 8px 0px 0px;
    background: #18181bed;
    color: white;
}
.settings_dialogue .k-window-content{
    background:#18181b ;

}
.settings_add_custom_domains_form {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.settings_add_custom_domains_form .row {
    margin: 0px;
    position: relative;
    display: block;
}

.settings_add_custom_domains_form .row .full_width_label {
    padding: 5px 0;
}

.settings_add_custom_domains_form .save_button,
.settings_add_custom_domains_form .cancel_button {
    width: auto;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    padding: 8px 20px;
    cursor: pointer;
}

.settings_add_custom_domains_form .cancel_button {
    border: 1px solid #ccc;
}

.settings_add_custom_domains_form .save_button {
    border: 1px solid var(--theme_dark_blue_color);
}

.radio_group li.k-radio-item input,
.default_checkbox input{
    width: 14px;
    height: 14px;
}
.radio_group li.k-radio-item input::before
{
    width: 6px;
    height: 6px;
    top: 50%;
    left: 53%;
}
.radio_group li.k-radio-item input:checked {
    border-color: #e0358b;
    color: #ffffff;
    background-color: #e0358b;
}

.radio_group li.k-radio-item .k-radio-label {
    margin-left: 7px !important;
    line-height: 16px;
}

.default_checkbox input::before {
    width: 9px;
    height: 9px;
    font-size: 9px;
}

.default_checkbox label {
    margin-left: 7px !important;
}

a {
    text-decoration: none;
}

.custom_domain_dialogue .k-dialog {
    min-width: 600px !important;
    width: 600px !important;
    min-height: 400px;
}
.custom_domain_dialogue .k-dialog .k-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.copy_group {
    background-color: #f6f7f9;
    border: 1px solid #ccc;
    border-radius: 4px;
    position: relative;
    padding: 8px 0px !important;
    background: transparent;
}
.copy_group .input_container input {
    width: calc(88% - 10px);
    display: inline-block;
    margin: 0 5px;
    background-color: transparent;
    color: var(--black);
    border: 1px solid #c3cfde;
    padding: 5px;
    font-size: 12px;
    border-radius: 4px;
    color: white;
    outline: none;
}
.copy_group .input_container .tags span  {
    margin: 0 0 0 10px;
    background-color: var(--theme_dark_blue_color);
    padding: 6px 6px;
    border-radius: 4px;
    display: inline-block;
    background: #e0358b;
    cursor: pointer;
}
.copy_group .input_container .tags {
    width: 12%;
    color: var(--white);
    display: inline-block;
}

.black { color: #000; }
.cname_info { padding: 20px; }
.cname_info p { padding: 0px; margin: 0 0 12px; }
.cname_info p.p2 { padding: 0px; margin: 12px 0 12px; }
.cname_info p.bold { font-weight: bold; }

.custom_domain_dialog_buttons button {
    border: 0px;
    padding: 8px;
}

.custom_domain_dialog_buttons .theme_dark_bg_color_imp:hover {
    background-color: var(--theme_dark_blue_bg_hover) !important;
    background-color: #ba2374 !important;
}
.normal_text{
    color: white;
}
.filtering-modal-content .normal_text{
    color: white !important;
}
.text_align_left a{
    color: #e0358b;
}
.text_align_left a:hover{
    color: #d43685;
}
.text_align_left p {
    color: white !important;
}
.select_dropdown select{
    color: white;
    background-color: transparent;
    border-color: #5B5859;
}
.select_dropdown select option{
    background: #222;
    color: white;
}
.settings_area_container .k-grid-table .k-master-row td {
    border: 1px solid #5B5859 !important;
    border-bottom: 0px !important;
    border-left: 0px !important;
    
}
.settings_area_container .k-grid-table .k-master-row:first-child td {
    border-top:0px !important;
    
}
.settings_area_container .k-grid-table .k-master-row .row {
    border: 1px solid #5B5859 !important;
    height: 42px;
    border-bottom: 0px !important;
    border-left: 0px !important;

}
.settings_area_container .k-grid-header-wrap th {
    border: 1px solid #5B5859 ;
    border-top: 0px;
    border-left: 0px;
    border-bottom: 2px solid #5B5859 ;
}

@media (max-width:1350px){
    .redirect_domains_container_row button:first-of-type{
        padding: 5px 23px !important;
        font-size: 14px !important;
    }
}
@media (max-width:900px){
    .user-settings .white_box_with_header_con ,.eventlog-settings .white_box_with_header_con {
        overflow-x: scroll;
    }
    .user-settings .white_box_with_header_con .box section {
        width: 1356px;
    }
    .eventlog-settings .white_box_with_header_con {
        width: 1000px;
    }

}
