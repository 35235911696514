.hide_column {
    display: none;
}

.grid tr:nth-child(even), .grid tr:nth-child(even) .checkbox-wrapper {
    background-color: #18181b;
    color: #fff;
    background-color:#a9a9a9;
    background-color: #222;
    background-color: #18181b;
}

.grid tr:nth-child(odd), .grid tr:nth-child(odd) .checkbox-wrapper {
    background-color: #fff;
    background-color: #2c2c2c;
    background-color: #18181b;
    background-color: #222;
    background-color: #1c1c20;
}

.grid tr {
    font-size: 12px;
    line-height: 15px;
    padding: 0px;
    cursor: pointer;
    font-weight: 400;
}

.grid {
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-collapse: collapse;
    color:white;
    border-collapse: separate;
    border-spacing: 0;
}
.grid tr td {
    padding: 6px;
    border: 1px solid #5B5859;
    /*border: 0px;*/
    color: white;
}
/*.grid td {*/
/*    max-height: 20px;*/
/*}*/
.grid th {
    max-height: 35px;
}
.grid tr th input[type="checkbox"],
.grid tr td input[type="checkbox"] {
    width: 16px;
    height: 16px;
}

.grid tr th input[type="checkbox"]:checked,
.grid tr td input[type="checkbox"]:checked {
    background-color: #2e4887;
}



.grid tr th {
    /*background: #67708B;*/
    background: #4e5069;
    background: #222;
    background: #161616;

    color: #fff;
    font-weight: 300;
    position: relative;
    padding: 0px;
    /*height: 40px;*/

    border-right: 1px solid #b1b1b1;
    border-bottom: 1px solid #b1b1b1;
    border-top: 1px solid #b1b1b1;

    /*border: 1px solid #5B5859;*/
    background: black;

}
.grid tr th:first-child{
    border-left: 1px solid #b1b1b1;
}

.resizingDiv {
    resize: horizontal;
    overflow: auto;
    height: 35px;
    width: 100%;
    margin: 0px;
    border: 0px solid black;
    padding: 0;
    /*display: flex;*/
    align-items: center;
    justify-content: flex-start;
    padding-left: 8px;
}
.table_header_row {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0;
    z-index: 1001;
}
.table_footer_row {
    position: -webkit-sticky !important;
    position: sticky !important;
    bottom: 0;
    z-index: 1000;
}
.table_footer_row th {
    height: 30px;
}
/*table th {*/
/*    resize: horizontal;*/
/*    overflow: auto;*/
/*}*/

/* .resizingDiv:hover { */
    /*border-right: 3px solid var(--theme_dark_blue_color);*/
/* } */

.sorting_icon_hover,
.sorting_icon_fixed {
    font-size: 12px;
    margin-left: 4px;
}
.sorting_icon_fixed {
    display: inline;
}
.sorting_icon_hover {
    display: none;
}
.table-scroll {
    position: relative;
    width:100%;
    z-index: 1;
    margin: auto;
    overflow: auto;
    height: 100%;
    height: 99.8%;
  }
.grid .resizingDiv:hover .sorting_icon_fixed {
    display: none;
}
.grid .resizingDiv:hover .sorting_icon_hover {
    display: inline;
}
.grid_sticky_td,
.grid_sticky_th {
    background-color: inherit;
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0;
    left: 0;
    z-index: 100;
    border-right: 1px solid #ccc;
    display: table-cell;
}
.grid .table_row {
    display: table-row;
    position: relative;
}
.grid .table_row:nth-child(even), .grid .table_row:nth-child(even) .checkbox-wrapper {
    background-color: #f3f3f3;
}
.grid .table_row:nth-child(odd), .grid .table_row:nth-child(odd) .checkbox-wrapper {
    background-color: #fff;
}
.grid .table_data_cell {
    display: table-cell;
    padding: 6px;
    vertical-align: middle;
    font-size: 12px;
    line-height: 15px;
    cursor: pointer;
    font-weight: 400;
}
/*.grid_sticky_th {*/
/*    padding: 6px !important;*/
/*}*/
.profit_change_con {
    display: flex;
}
.profit_change {
    padding: 0px !important;
    min-width: 10px;
    position: relative;
    top: -6px;
}
.profit_change .neutral,
.profit_change .plus,
.profit_change .minus,
.profit_change .empty {
    width: 100%;
    height: 100%;
    display: block;
    overflow: visible;
}

.profit_change .neutral span,
.profit_change .plus span,
.profit_change .minus span {
    width: 100%;
    height: calc(100% + 12px);
    display: flex;
    position: relative;
    /*top: -6px;*/
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 16px;
}
.profit_change .neutral span {
    color: #8d8d8d;
    /*background-color: #e1e7ee;*/
    background-color: #c5c5c5;
    background-color: #18181b;
    background-color: #080808;
    color: #080808;

    background-color:#3c3c3c;
    color:#3c3c3c;
}
.profit_change .plus span {
    background-color: #29af61;
    color: #fff;
}
.profit_change .minus span {
    background-color: #d25454;
    color: #fff;
}
.grid tr.odd-row{
    background-color: #18181b; 
}
.grid tr.even-row{
   background-color: #222;

   background-color: #18181b;
   background: #1c1c20;
}