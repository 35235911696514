.hide_column {
    display: none;
}

.grid tr:nth-child(even), .grid tr:nth-child(even) .checkbox-wrapper {
    background-color: #f3f3f3;
}

.grid tr:nth-child(odd), .grid tr:nth-child(odd) .checkbox-wrapper {
    background-color: #fff;
}

.grid tr {
    font-size: 12px;
    line-height: 15px;
    padding: 0px;
    cursor: pointer;
    font-weight: 400;
}

.grid {
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #18181b;
}
#grid tr div{
    background: #18181b;
}
#grid tr th div{
    background:unset;
    background: #161616;
    background: black;
}
#grid .grid_sticky_td div{
    background: unset;
}
.grid tr td {
    padding: 6px;
}
/*.grid td {*/
/*    max-height: 20px;*/
/*}*/
.grid th {
    max-height: 35px;
}
.grid tr th input[type="checkbox"],
.grid tr td input[type="checkbox"] {
    width: 16px;
    height: 16px;
}

.grid tr th input[type="checkbox"]:checked,
.grid tr td input[type="checkbox"]:checked {
    background-color: #2e4887;
}

.grid tr:hover {
    background-color: var(--color_theme_default_bg_hover);
}

.grid tr th {
    /*background: #67708B;*/
    background: #4e5069;
    color: #fff;
    font-weight: 300;
    position: relative;
    padding: 0px;
    /*height: 40px;*/
}

.resizingDiv {
    resize: horizontal;
    overflow: auto;
    height: 35px;
    width: 100%;
    margin: 0px;
    border: 0px solid black;
    padding: 0;
    /*display: flex;*/
    align-items: center;
    justify-content: flex-start;
    padding-left: 8px;
}
.table_header_row {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0;
    z-index: 1001;
}
.table_footer_row {
    position: -webkit-sticky !important;
    position: sticky !important;
    bottom: 0;
    z-index: 1000;
}
.table_footer_row th {
    height: 30px;
}
/*table th {*/
/*    resize: horizontal;*/
/*    overflow: auto;*/
/*}*/

.resizingDiv:hover {
    /*border-right: 3px solid var(--theme_dark_blue_color);*/
}

.sorting_icon_hover,
.sorting_icon_fixed {
    font-size: 12px;
    margin-left: 4px;
}
.sorting_icon_fixed {
    display: inline;
}
.sorting_icon_hover {
    display: none;
}
.table-scroll {
    position: relative;
    width:100%;
    z-index: 1;
    margin: auto;
    overflow: auto;
    height: 100%;
  }
.grid .resizingDiv:hover .sorting_icon_fixed {
    display: none;
}
.grid .resizingDiv:hover .sorting_icon_hover {
    display: inline;
}
.grid_sticky_td,
.grid_sticky_th {
    background-color: inherit;
    /*position: -webkit-sticky !important;
    position: sticky !important;
    top: 0;
    left: 0;*/
    z-index: 100;
    border-right: 1px solid #ccc;
    display: table-cell;
}
.grid .table_row {
    display: table-row;
    position: relative;
}
.grid .table_row:nth-child(even), .grid .table_row:nth-child(even) .checkbox-wrapper {
    background-color: #f3f3f3;
}
.grid .table_row:nth-child(odd), .grid .table_row:nth-child(odd) .checkbox-wrapper {
    background-color: #fff;
}
.grid .table_data_cell {
    display: table-cell;
    padding: 6px;
    vertical-align: middle;
    font-size: 12px;
    line-height: 15px;
    cursor: pointer;
    font-weight: 400;
}
/*.grid_sticky_th {*/
/*    padding: 6px !important;*/
/*}*/
.profit_change_con {
    display: flex;
}
.profit_change {
    padding: 0px !important;
    min-width: 10px;
    position: relative;
    top: -6px;
}
.profit_change .neutral,
.profit_change .plus,
.profit_change .minus,
.profit_change .empty {
    width: 100%;
    height: 100%;
    display: block;
    overflow: visible;
}

.profit_change .neutral span,
.profit_change .plus span,
.profit_change .minus span {
    width: 100%;
    height: calc(100% + 12px);
    display: flex;
    position: relative;
    /*top: -6px;*/
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 16px;
}
.profit_change .neutral span {
    color: #8d8d8d;
    /*background-color: #e1e7ee;*/
    background-color: #c5c5c5;
}
.profit_change .plus span {
    background-color: #29af61;
    color: #fff;
}
.profit_change .minus span {
    background-color: #d25454;
    color: #fff;
}
/*#grid tbody{
    height: min-content !important;
    background: #5B5859;
}*/

.grid .resizingDiv{
    /*width: 100px;*/
    overflow: hidden;
    min-width: unset !important;
    min-width:30px !important;
    padding-left: 8px !important;
}
.grid .table_footer_row th {
    min-width:unset !important;

}
.grid .table_header_row th{
    /*min-width: unset !important;*/
    text-align: left;
}
.grid tr td:nth-child(3) {
    max-width: 0 !important;

}
/*.grid .table_header_row th:nth-child(3) .resizingDiv{
    width: 250px;
}*/
.grid tr td:nth-child(5) {
    max-width: 0 !important;
    overflow: hidden;

}
/*.grid .table_header_row th:nth-child(5) .resizingDiv{
    width: 200px;
}*/
.grid tr td {
    overflow: hidden;
    max-width: 0 !important;
    white-space: nowrap;
}
.grid tr th {
    overflow: hidden;
    white-space: nowrap;

}
.grid .table_footer_row th{
    max-width: 0 !important;
}
/*.grid .table_header_row th:nth-child(10){
    width: unset !important;
}*/
/*.grid .table_header_row th:nth-child(10) .resizingDiv{
    width:  122px ;
}*/
.grid .resizingDiv div[data-id="tooltip"]{
    white-space: pre-wrap;
}
#grid .table_header_row th {
    white-space: pre-wrap;
}
#grid #inside_section_background{
    background-color: black;
}
#grid #inside_section_background:first-of-type{
    background-color: red;
    border: 1px solid white;
}
#grid #inside_section_background-2{
    background-color: #8d8d8d;
    background: #222;
}
#grid .inside_section_background td:first-child{
    padding-left: 16px;
}
#grid #inside_section_background td:first-child{
    padding-left: 16px;
}
#grid #inside_section_background-2 td:first-child{
    padding-left: 26px;
    min-width: 45px;
}
/*#grid .inside-first-row td {
    border-top: 1px solid #e0358b;
}*/