@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
body{
    margin: 0;
    padding: 0;
}

.page_container {
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    overflow-y: auto;
    background: #f2f2f2;
}
.page_container .form_page_logo_container {
    display: block;
    position: relative;
    width: 100%;
    overflow: auto;
    text-align: left;
    padding: 40px 40px 0px 40px;
}
.page_container .form_page_logo_container .inner {
    display: flex;
    align-items: center;
}
.page_container .form_page_logo_container img {
    width: 50px;
    display: inline-block;
}
.page_container .form_page_logo_container h1 {
    display: inline-block;
    padding: 0;
    margin: 0 0 0 15px;
    border: 0px;
    line-height: 100%;
    font-family: 'Montserrat', sans-serif;
    /*font-family: ;*/
}

.page_container .container{
    padding: 20px;
}

.page_container .container form {
    max-width: 550px;
    margin: 8% auto 0;
    padding: 40px 30px 30px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #f2f2f2;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.1);
    transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    -webkit-transition: all 0.6s ease;
    background: #fff;
}

.page_container .container form:hover {
    box-shadow: 0px 2px 20px rgba(0,0,0,0.3);
}

.login_h2 {
    margin-top: 0;
    padding: 0 0 20px 0;
    color: #000;
    border-bottom: 1px solid black;
    font-family: 'Montserrat', sans-serif;
}

.login_links {
    color: #2691d9;
    text-decoration: none;
    font-size: medium;
}
.login_links:hover{
    transition: .5s;
    text-decoration: underline;
}

.signinbutton{
    width: 55%;
    height: 40px;

    background: #e0358b;
    border-radius: 25px;
    /* font-size: 18px; */
    color: #e9f4fb;
    /* font-weight: 100; */
    cursor: pointer;
    outline: none;
}

.signinbutton:hover{
    background: #ba2374;
    transition: .5s;
    color: #e9f4fb;
}
/*button[type="button"]:hover{*/
/*    border: 1px solid;*/
/*    border-radius: 25px !important;*/
/*    transition: .5s !important;*/
/*    background: white !important;*/
/*    outline: none !important;*/
/*    color: black !important;*/
/*}*/
/*button[type="button"]{*/
/*    width: 50%;*/
/*    border: 1px solid !important;*/
/*    border-radius: 25px !important;*/
/*    outline: none !important;*/
/*    outline-style: none !important;*/
/*    background: white !important;*/
/*    !* font-size: 15px !important; *!*/
/*    transition: .5s !important;*/
/*}*/
.googleLogin>div{
    margin-left: 30px;
    outline: none !important;
    font-weight: 700 !important;
}

.googleLogin>span{
    outline: none !important;
    font-weight: 700 !important;
}



.form_element {
    max-width: 500px;
    margin: 0vh auto 0;
    
}
#email,#password,#confirmPassword{
    padding: 20px;
    border: 1px solid;
    border-radius: 5px;
}

/* .k-form-error{
    font-size: small;
    color: tomato;
    font-weight: 400;
} */
.k-label{
    font-size: medium;
    color: #adadad;
}
/* k-textbox-container k-state-empty{
    height: 200%;   
    width: 0;
} */

.signinbutton{
    width: 50%;
}


.separator {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap; 
    margin: 20px 0;
}

.separator > span {
    position: relative;
    display: inline-block;
}

.separator > span:before,
.separator > span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: rgba(0, 0, 0, 0.35);
}

.separator > span:before {
    right: 100%;
    margin-right: 15px;
}

.separator > span:after {
    left: 100%;
    margin-left: 15px;
}
#email {
    background-color: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: white;
}
.hide-buttons #sign-in-btn{
    z-index: -1;
    color: transparent;
    background: transparent;
    border-color: transparent !important;

}
.hide-buttons #sign-up-btn{
    z-index: -1;
    color: transparent;
    background: transparent;
    border: transparent;
}

@media (max-width:768px){
    .hide-buttons .nav-bar .header_auto {height: 300px !important}
}