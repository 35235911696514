
.notes p{
	display: flex;
	line-height: 1.33;
    box-sizing: border-box;
	text-align: left;
	font-size: 0.75rem;
	margin-left: 0px;
	padding-left: 0px;
}
dl{
    margin-bottom: 0px;
}

.flow-collapse-child p{
	margin-top: 10px;
}

.notes .notes {
	cursor: pointer;
	color: black;
	margin-left: 4.66666667em;
    display: flex;
    flex-wrap: wrap;
}
.path-animation .k-animation-container {
	width: 100% !important;
	z-index: 1;
}

.rule-collapse-child{
	padding-right: 2.66666667em;
    padding-left: 5.33333333em;
	padding-top: 1.0em;
}
.rule-right-btns{
	padding-right: 20px;
    display: flex;
    flex-direction: row;
}



.flow-collapse-child textarea{
	border-width: 1px;
    box-sizing: border-box;
    padding: 7px;
	resize: none;
	background-color: #fff;
    border: 1px solid #c3cfde;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 400;
    height: 100px;
    line-height: 16px;
    outline: none;
	width: 400px;
	
}

.flow-collapse-child textarea:focus{
	border-color: #6786DE;
}

.notes-arrow-up-down{
	position: absolute;
}
.notes-arrow-up-down span{
	color: #6786DE;
	font-size: 20px;
	top: -4px;
	left: 33px;
	cursor: pointer;
	

}

ol{
	line-height: 1.25rem;
	padding-left: 16px;
}

.conditions-sperator{
	color: #C3CFDE !important;
	margin: 0px 5px 0px 5px;
	
}
.rule-notes-name p{
	margin: 0%;
}
