.card{
	background: white;
	width: 600px;
}

#userName, #lastName{
    padding: 20px;
    border: 1px solid;
    border-radius: 5px;
}

.registration_success_para {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    padding: 10px;
    margin: 15px 0 2px;
    border-radius: 4px;
}

.registration_failure_para {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    padding: 10px;
    margin: 15px 0 2px;
    border-radius: 4px;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
body{
    margin: 0;
    padding: 0;
}

.page_container {
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    overflow-y: auto;
    background: #f2f2f2;
}
.page_container .form_page_logo_container {
    display: block;
    position: relative;
    width: 100%;
    overflow: auto;
    text-align: left;
    padding: 40px 40px 0px 40px;
}
.page_container .form_page_logo_container .inner {
    display: flex;
    align-items: center;
}
.page_container .form_page_logo_container img {
    width: 50px;
    display: inline-block;
}
.page_container .form_page_logo_container h1 {
    display: inline-block;
    padding: 0;
    margin: 0 0 0 15px;
    border: 0px;
    line-height: 100%;
    font-family: 'Montserrat', sans-serif;
}

.page_container .container{
    padding: 20px;
}

.page_container .container form {
    max-width: 550px;
    margin: 8% auto 0;
    padding: 40px 30px 30px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #f2f2f2;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.1);
    transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    -webkit-transition: all 0.6s ease;
    background: #fff;
}

.page_container .container form:hover {
    box-shadow: 0px 2px 20px rgba(0,0,0,0.3);
}

.login_h2 {
    margin-top: 0;
    padding: 0 0 20px 0;
    color: #000;
    border-bottom: 1px solid black;
    font-family: 'Montserrat', sans-serif;
}

.login_links {
    color: #2691d9;
    text-decoration: none;
    font-size: medium;
}
.login_links:hover{
    transition: .5s;
    text-decoration: underline;
}

.register-btn {
    width: 55%;
    height: 40px;
    border: 1px solid;
    background: #e0358b;
    border-radius: 25px;
    font-size: 18px;
    color: #e9f4fb;
    font-weight: 700;
    cursor: pointer;
    outline: none;
    border-color: #e0358b;
    margin-bottom: 5px;
}

.register-btn:hover{
    background: #ba2374;
    border-color:#ba2374;
    transition: .5s;
    color: #e9f4fb;
}
/* button[type="button"]:hover{
    border: 1px solid;
    border-radius: 25px !important;
    transition: .5s !important;
    background: white !important;
    outline: none !important;
    color: black !important;
}
button[type="button"]{
    width: 50%;
    border: 1px solid !important;
    border-radius: 25px !important;
    outline: none !important;
    outline-style: none !important;
    background: white !important;
    font-size: 15px !important;
    transition: .5s !important;
} */
.googleLogin>div{
    margin-left: 30px;
    outline: none !important;
    font-weight: 700 !important;
}

.googleLogin>span{
    outline: none !important;
    font-weight: 700 !important;
}



.form_element {
    max-width: 500px;
    margin: 0vh auto 0;

}
#email,#password,#confirmPassword{
    padding: 20px;
    border: 1px solid;
    border-radius: 5px;
}

.k-label{
    font-size: medium;
    color: #adadad;
}
/* .k-textbox-container .k-state-empty{
    height: 200%;
    width: 0;
} */

.signinbutton{
    width: 50%;
}


.separator {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    margin: 20px 0;
}

.separator > span {
    position: relative;
    display: inline-block;
}

.separator > span:before,
.separator > span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: rgba(0, 0, 0, 0.35);
}

.separator > span:before {
    right: 100%;
    margin-right: 15px;
}

.separator > span:after {
    left: 100%;
    margin-left: 15px;
}

.register_form_element .input_group_phone ,
.register_form_element .input_group {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
}
.register_form_element .input_group label {}
.register_form_element .input_group input,
.register_form_element .input_group .select_dropdown_acc {
    padding: 7px !important;
    border: 1px solid #5B5859!important;
    border-radius: 5px !important;
    font-size: 13px !important;
    line-height: 17px !important;
    color: white;
    background: none;
    outline: none;
    transition: 0.5s all ease;

}
.register_form_element .input_group input:focus{
    border: 1px solid ;
    
}
.register_form_element option {
    background-color: rgb(24, 24, 27);
    color: white;
}
.register_form_element select {
    background: none;
    color: #fff;
    outline: none;

}

.instant-messenger{
    display: flex;
}
#instant_messenger_number{
    border-left-style: none !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    width: 100%;
}
.instant-messenger-select{

    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;

}
input[type=text]:focus {
    border: 1px solid #e0358b !important;
}
label{
    color:hsla(0,0%,100%,.85);
}
label::after{
    display: inline-block;
    margin-right: 4px;
    color: #a81f27;
    font-size: 14px;
    font-family: SimSun,sans-serif;
    line-height: 1;
    content: "*";
}
.k-radio-label::after{
    content: "";
}
label[for="linkedin or facebook"]::after{
    content: "";
}
label[for="website"]::after{
    content: "";
}
.k-radio:checked{
    border-color: #e0358b;
    background-color:#e0358b ;
}
#password::selection{
    background-color: #e0358b !important
}
#confirmPassword::selection{
    background-color: #e0358b !important
}
.country_dropdown:-webkit-autofill,
.input_group input:-webkit-autofill,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: white;
}
* {
    scroll-behavior: smooth;
}