.row {}
.row .h4 {
    font-size: 14px;
    line-height: 18px;
    color: #000;
}
.row .h4 .bold {
    font-weight: 600;
    min-width: 220px;
    text-align: left;
    display: inline-block;
}
.row .h4 span.normal {
    font-weight: 400;
    background: #f2f2f2;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.row p {
    margin-left: 0px;
    padding-left: 0px;
}

.update_cost_dialogue h4 {
    text-align: left;
    margin: 10px 0px 5px;
    display: block;
    color: white;
}


.update_cost_dialogue input.inp {
    display: block;
    width: 100%;
    color: var(--black);
    border: 1px solid #f2f2f2;
    padding: 7px;
    border-radius: 4px;
}

.update_cost_dialogue input:active,
.update_cost_dialogue input:focus {
    outline: none;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.2);
    border: 1px solid var(--theme_blue_color);
}

.update_cost_dialogue .flex_row {
    display: flex;
}
.five_hun_half_con .five_hun_half .inner .weightage_inner_con .numbers_con {
    right: 6px;
}

.five_hun_half_con .five_hun_half .inner .weightage_inner_con .input_con input {
    padding: 0px 30px 0px 5px;
    margin: 0;
}

.numbers_con span {
    cursor: pointer;
}

.cost_currency_con {
    grid-gap: 15px;
}
.date_con {
    display: flex;
    grid-gap: 20px;
    align-items: center;
}
.date_con .inner {
    display: flex;
    grid-gap: 10px;
}

#startTime,
#endTime,
.react-datepicker-ignore-onclickoutside,
.react-datepicker__input-container input {
    color: var(--black);
    border: 1px solid #f2f2f2;
    padding: 7px;
    background: transparent;
    color: white;
    
}
.k-window .k-dialog-buttongroup {
    background-color: #1e1e20;
}