.static_profile_con .label_content {
    /*padding: 1.33em;*/
    font-size: 16px;
    margin: 8px 0;
}
.static_profile_con .profile_row {
    margin-bottom: 20px;
}
.static_profile_con  .profile_row:last-of-type {
    margin-bottom: 0px;
    text-align: right;
}
.static_profile_con .profile_row .edit_button {
    margin: 0 5px;
}
.static_profile_con .profile_row .edit_button span {
    color: #2e4887;
    fill: #2e4887;
}
.static_profile_con .profile_row .profile_form_btn {
    min-width: 100px;
}
.static_profile_con .profile_row .theme_dark_bg_color {
    background: var(--theme_dark_blue_color);
    background: #e0358b;
}
.static_profile_con .profile_row .theme_dark_bg_color:hover {
    background: var(--theme_dark_blue_bg_hover);
    background: #ba2374;
}
.static_profile_con .profile_row input{
    background: transparent;
    color: white;
}
.static_profile_con .profile_row input:focus{
    border: unset !important;
}