.redirect_domains_radio_check_inputs {
    margin: 8px 0 15px;
    font-size: 12px;
}

input.redirect_domains_radio_check_inputs {
    padding: 4px 5px;
}
.verify_dns {
    margin-left: 15px;
}
.verify_dns span {
    width: 12px;
    height: 12px;
    font-size: 12px;
}
.redirect_domains_container_row button:first-of-type {
    margin-right: 10px;
}