.box-left {
    display: flex;
    justify-content: flex-end;
}

.header-second-row {
    padding:7px 5px;
    display:flex;
    align-items:center;
    background: white;
    background: unset;
    justify-content:flex-end;
    border-bottom: 1px solid #5B5859;
}