.row {}
.row .h4 {
    font-size: 14px;
    line-height: 18px;
    color: #000;
}
.row .h4 .bold {
    font-weight: 600;
    min-width: 220px;
    text-align: left;
    display: inline-block;
    color: white;
}
.row .h4 span.normal {
    font-weight: 400;
    background: #f2f2f2;
    padding: 5px 10px;
    border: 1px solid var(--light_grey_border_color);
    border-radius: 4px;
}

.row p {
    margin-left: 0px;
    padding-left: 0px;
}

.columns_dialog_content {
    position: relative;
    height: 100%;
    overflow: hidden;
}

.columns_dialog_content .columns_con {
    height: calc(100% - 66px);
    border: 1px solid var(--light_grey_border_color);
    border-radius: 8px;
    padding: 0px;
    overflow: hidden;
}

.columns_dialog_content .columns_con .select_all {
    padding: 0px 15px;
    border-bottom: 1px solid var(--light_grey_border_color);
}

.columns_con .checkbox_con {
    display: flex;
    align-items: center;
    height: 26px;
    line-height: 100%;
}

/*.checkbox_con input {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    width: 13px;*/
/*    line-height: 100%;*/
/*    margin: 0 10px 0 0;*/
/*}*/

.columns_con.checkbox_con label {
    margin: 0;
    line-height: 26px;
    font-weight: 500;
    padding-top: 1px;
}

.columns_con .columns {
    padding: 15px;
    overflow: auto;
    max-height: calc(100% - 27px);
    display: grid;
    grid-template-columns: 1fr 1fr;
}

/* The container */
.columns_con .checkbox_con {
    display: block;
    position: relative;
    padding-left: 24px;
    padding-top: 8px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.columns_con .checkbox_con input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.columns_con .checkmark {
    position: absolute;
    top: 7px;
    left: 0;
    height: 13px;
    width: 13px;
    background-color: #eee;
    border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.columns_con .checkbox_con:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.columns_con .checkbox_con input:checked ~ .checkmark {
    background-color: #6786DE;
    background-color: #e0358b;
}

/* Create the checkmark/indicator (hidden when not checked) */
.columns_con .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.columns_con .checkbox_con input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.columns_con .checkbox_con .checkmark:after {
    left: 4px;
    top: 1px;
    width: 3px;
    height: 6px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}