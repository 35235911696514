.workspace_popup_con_outer {
    padding: 5px 0;
    display: inline-block;
}
.workspace_popup_con {
    display: inline-block !important;
    margin-left: 15px;
    min-height: 30px;
}
.workspace_popup_con .k-button {
    padding: 0.5em 1em;
    max-width: 17em;
    line-height: 1.33333333em;
    font-weight: 500;
    background: transparent;
}
.workspace_popup_con .k-button:hover {
    color: #e0358b;
    transition: 0.5s ease;
}
.workspace_popup_con .img_con {
    padding: 0;
    max-width: 17em;
    border: 0px;
    line-height: 1.33333333em;
    font-weight: 500;
    background: transparent;
    width: 40px;
    height: 10px;
    position: relative;
    top: 5px;
}
.workspace_popup_con .img_con .profile_img {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    object-fit: cover;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
}
.workspace_popup_con .down_icon {}
.popup_header {
    min-width: 200px;
    color: #8892AA;
    box-sizing: border-box;
    padding: 0.33333333em 1.66666667em;
    background-color: #F6F7F9;
    border-radius: 4px 4px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: white;
    background: #18181bed;
}
.popup_body {
    padding: 0;
    background: #fff;
    font-size: 12px;
    background: #18181b;
}
.popup_body .k-state-default {
    display: block;
    overflow: auto;
    margin: 0;
    padding: 0.8em;
    border-bottom: 1px solid #F0F3F7;
    /*box-sizing: border-box;*/
    /*margin: 1em 0em 1.33333333em;*/
    /*border-width: 1px 0px 0px;*/
    /*border-color: #F0F3F7;*/
    /*border-style: solid;*/
}
.popup_body .link_span {
    display: inline-block;
    width: 100%;
    padding: 0.7em;
    color: #2E4887;
    font-size: 12px;
}
.popup_body .link_span:hover {
    background: #F6F7F9;
    color: #6786DE;
    background: #29292e;
}
.k-checkbox:checked {
    border-color: #6786DE;
    color: #ffffff;
    background-color: #6786DE;
}

.workspace_backdrop {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 10000;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.3);
}

.workspaces_apply_button {
    padding: 5px;
    text-align: right;
    border-top: 1px solid #5B5859;
    top: -1px;
    position: relative;
}
.workspaces_apply_button button {
    color: #fff;
    background-color: var(--color_theme_dark);
    border: 0px;
    border-radius: 5px;
    line-height: 18px;
    font-size: 13px;
    padding: 5px 20px;
    background: #e0358b;
}

.workspaces_apply_button button:hover {
    background-color: var(--theme_dark_blue_bg_hover);
    background: #ba2374;
}

.workspaces_apply_button button:disabled {
    background-color: var(--theme_dark_blue_color_disabled);
    cursor: not-allowed;
    background: #ba2374;
}

.workspaces_list_label {
    display: flex;
    align-items: center;
    padding: 6px;
    border-bottom: 1px solid #5B5859;
    line-height: 17px;
    font-size: 13px;
    cursor: pointer;
}
.workspaces_list_label input {
    margin: 3px 5px;
}
.k-animation-container .k-popup{
    background-color: #18181b;
}
.popup_header .text{
    color: white;
}