.add_custom_domain_dropdown,
.add_custom_domain_input {
    display: block;
    width: 100%;
    color: var(--black);
    border: 1px solid #f2f2f2;
    padding: 7px;
    background: transparent;
    color: white;
}

.add_custom_domain_dropdown:focus,
.add_custom_domain_dropdown:active,
.add_custom_domain_input:focus,
.add_custom_domain_input:active {
    border: 1px solid #f2f2f2;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.7);
}

.domain_error {
    margin: 5px 0;
    font-size: 12px;
    font-weight: 400;
    color: var(--color_danger);
    border-top: 1px solid var(--color_danger);
    padding: 5px;
}

.add_custom_domain_row button,
.redirect_domains_container_row button:first-of-type {
    border: 0;
    border-radius: 4px;
    padding: 5px 10px;
    margin-right: 0px;
}
.add_custom_domain_dropdown option {
    color: white;
    background-color: #111;
}
