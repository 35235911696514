.offers, .landers, .lander-name, .offer-name{
    justify-content: space-between;
}

.path-offer-redirect label{
    box-sizing: border-box;
    margin-left: 1.66666667em;
    line-height: 0.75rem;
    font-size: 0.75rem;
    font-weight: 100;
    margin-bottom: 0px !important;
    
}
.new-path-input{
    width: -webkit-fill-available;
}
.radio-buttons{
    padding-left: 0;
}
.path .switch{
    padding-left: 0;
}

.push{
    margin-left: auto;
} 
/* .path hr{
    border-color: silver ;
    box-sizing: border-box;
    margin-top: 1em;
    margin-bottom: 1em;
}*/