.last_switches_row textarea {
    width: 100%;
    height: 150px;
    border-radius: 4px;
    background-color: #f6f7f9;
    border: 1px solid #ccc;
    padding: 10px;
}
.last_switches_row {
    flex-direction: column;
}

.last_switches_row p {
    padding: 10px !important;
}
.last_switches_row p.one {
    padding: 0px !important;
}

.success_msg_con {
    margin: 20px 0;
}
.success_msg_con div,
.success_msg_con p {
    width: 100%;
}

.payout_input{
    width: 90px;
    /* height: 28px; */
    border:none;
}
.payout_input:focus{
    border-color: #6786de;
}

.payout_input:disabled{
    background: #f6f7f9;
    border-color: #c3cfde;
    color: #a6adc0;
}
.payout_input::-webkit-outer-spin-button,
.payout_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.payout_form{
    display:flex;
    flex-direction:row;
    border:1px solid grey;
}

.payout_input:focus {
    outline: none ;
  }

.btn-clr{
    color: #2e4887;
}
.btn-clr:hover{
    color: #2e4887;
}
  