.header_tabs {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #f2f2f2;
    background: linear-gradient(#202020,#111);
    color: #e9e9e9;
    display: flex;
    align-items: flex-end;
    border-color: #5B5859;
    background:#1c1c1c;
    background: #18181a;
}

.header_tabs .tab {
    background-color: #f0f3f7;
    display: inline-block;
    border: 1px solid #e1e7ee;
    padding: .66666667em 1.16666667em;
    font-size: 12px;
    color: #8892AA;
    margin: 0 5px;
    background: rgb(14, 14, 16) !important;
    color: white;
    border: 0px;
    border-bottom:0px !important ;
}


.header_tabs .tab span {
    cursor: pointer;
}

.header_tabs .tab:hover {
    background-color: #fff;
    background:  rgb(14, 14, 16);
}

.header_tabs .tab .k-icon {
    font-size: 16px;
    line-height: 100%;
    width: 14px;
    height: 14px;
    position: relative;
    top: -1px;
    margin-left: 4px;
}