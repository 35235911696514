.eventlog-combobox, .eventlog-btn, .eventlog-dp{
    margin-left: 10px;
}
.m-b{
    margin-bottom: 1em;
}
.m-l{
    margin-left: 22px;
}

.hidden{
    color: white;
    visibility: hidden;
    overflow: hidden;
}

.refresh-btn{
    color: white;
    background: #2E4887;
    background: #e0358b;
    align-items: center;
    box-sizing: border-box;
    min-width: 6.66666667em;
    line-height: 1.7em;
    border-radius: 0.25em;
    font-weight: 500;
    font-size: 12px;
}

.refresh-btn:hover{
    color: white;
    background: #203975;
    background: #ba2374;
}

.eventlog-dp .k-daterangepicker-wrap{
    display: inline-flex !important;
}
.event-log .k-dialog .k-dialog-titlebar .k-dialog-title{
    text-align: center !important;
    display: block !important;
}
.event-log .k-dialog .k-dialog-titlebar{
    box-shadow: unset !important;
    border-style: unset !important;
}

.event-log .k-dialog .k-dialog-content{
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
}
.event-log .k-dialog .k-dialog-content .event-log-header{
    text-align: center;
    padding-bottom: 16px;
    /* box-shadow: 0px 4px 8px rgb(0 0 0 / 10%); */
    font-size: 16px;
    color: black;
    color: white;
}
.event-log .k-dialog .k-dialog-content .custom-search-items{
    padding-left: 28px;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 10%);
}
.event-log .k-dialog .k-dialog-content .custom-search-items .custom-search-row Input{
    width: 100%;
    color: white;
}
.event-log .k-dialog .k-dialog-content .custom-search-items .custom-search-row Input:focus{
    border-color: #e0358b;

}
.event-log .k-dialog .k-dialog-content .add_affiliate_network_dialogue_content{
    padding-left: 4em;
}
.settings_area_container .k-cell-inner {
    color: white;
    background-color: #e0358b;
}
.k-grid {
    color: white;
    border-color: #5B5859;
}
.k-grid-table tr {
    background-color: #222;
    color: white;
    background-color: #18181b;
}
.workspace_tab .k-grid-table tr .row span {
    color: rgb(155, 154, 154)
}
.k-grid-table tr:hover{
    background-color: #333!important;
}
.text_align_left .k-pager-wrap {
    background-color: #111 ;
    color: white;
}
.settings_area_container .k-column-title{
    color: white;
}
.k-grid-table .k-alt{
    background-color: #2c2c2c !important;
    background: #151518 !important;
    background: #1c1c20 !important;
}