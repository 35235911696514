
p{
	display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
	margin-inline-end: 0px;
	line-height: 1.33;
    box-sizing: border-box;
	margin: 0em 1.33333333em;
	text-align: left;
	font-size: 0.75rem;
	padding-left: 6px;
}
dl{
    margin-bottom: 0px;
}

.collapse-child p{
	margin-left: 30px !important;
	margin-top: 10px;
}

.pointer .pointer {
	cursor: pointer;
}

.flex{
	display: flex;
	cursor: pointer;
}

.arrow-up-down{
	
	margin: 0;
	padding: 0;
	padding-top: 1.33333333em;
    padding-right: 1.33333333em;
    /* padding-bottom: 1.33333333em; */
	padding-left: 1em; 
}

.arrow-up-down span{
	color: #2E4887;
	top: -27px;
    left: 30%;
	font-size: 20px;
	
}

ol{
	line-height: 1.25rem;
	padding-left: 16px;
}
.link{
	font-weight: 300;
	color: gray;
}
