
/* font-family: Roboto, Arial, Helvetica, sans-serif; font-size: 12px; */

.h1_collaborations {
    font-weight: 700;
    line-height: 2rem;
    font-size: 1.5rem;
    margin: 1em 18px;
	padding: 0px;
    border-bottom: none;
    color: black;
    color: white;
}
.h1_collaborations_modal {
    font-weight: 700;
    line-height: 2rem;
    font-size: 1.5rem;
    margin: 1em 18px;
	padding: 0px;
    border-bottom: none;
    color: black;
    border-bottom: 1px solid silver;
}
.k-item { margin-left: 10px !important; width: 180px;}
.text_center{text-align: center }
.workspace_tab div ul li span{ display: block !important; color: #6786DE !important;color: #e0358b !important;background: rgb(14, 14, 16);}
.workspace_tab div div{ font-size: 0.75rem; padding: 3px 0;}
/* .workspace_tab div div div div div {width: 2240px;} */

.private_workspace{text-align: left; display: flex; justify-content: space-between;}
.workspace_btn .add_workspace_btn{ background: #2cb967 ; background: #e0358b; color: #FFFFFF; border-radius: 3px; }
.workspace_btn .add_workspace_btn:hover{ background: #26a35a ;background:#ba2374 ; color: #FFFFFF; transition: 0.5s;}


.available_worspace_info{display: flex; justify-content: left; }
.available_worspace_info span{margin-right: 3px;}
.available_worspace_info .information_text{ color: black; margin: 0; padding: 0;}

/* .add_worspace_dialog{
    padding: 16px;
} */

.dialog_title_name div{
    border-radius: 5px;
    
}
.dialog_title_name div div{
    background-color: white;
}
.k-widget .k-window-titlebar{
    background-color: #18181bed;
    border-radius: 0px;
}
.k-widget .k-window-titlebar .k-window-title{
    background-color: #28282b;
    color: white;
}
.k-widget .k-window-titlebar .k-window-actions{
    background: transparent;
}
.k-widget .k-window-titlebar .k-window-actions a span{
    color: white !important;
}
.k-widget .k-window-content{
    background: #18181b;
    margin: 0px !important;
    border-radius: 0px 0px 5px 5px;
    color: white;
}
.k-widget .k-window-content .workspace_modal_heading_text{
    background: transparent;

}
.k-widget .k-window-content .workspace_modal_heading_text .text{
    color: white;
}
.k-widget .k-window-content .footer_buttons{
    background: transparent;
}
.k-widget .k-window-content .input_workspace_name {background: transparent;color:white;}
/* .dialog_title_name div div div{ padding: 16px;
padding-left: 0px;} */

.dialog_title_name div div .k-dialog-title{
    font-weight: 600;
    line-height: 2rem;
    font-size: 1.5rem;
    margin: 0em 18px;
    border-bottom: none;
    color: black;
    padding: 0px;
}
.dialog_title_name div div .k-dialog-actions, .dialog_title_name .k-dialog-content{
    margin: 0em 18px;
}
.workspace_modal_heading_text .text{
    line-height: 1.25rem;
    font-size: 1rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
    margin: 8px 0em 5px;
    padding: 0;
}

.dialog_title_name .input_workspace_name{
    width: 100%;
}
.error  {
    background-color: #fce4e4;
    border: 1px solid #cc0033;
    outline: none;
  }

.arrow{ color: #2e4887;position: relative; top: -100px; left: 440px; font-size: 13px !important;}
.dialog_title_name div div div a span{color: #2e4887 !important};
.add_worspace_dialog .workers_text_field{
    font-size: 0.75rem;
    padding: 0;
    margin: 0;
}

.add_worspace_dialog .k-header{
    padding: 3px 0;
}

.info_text{    
    font-size: small;
    font-weight: 100; padding: 0;
    margin: 0;
}

.dialog_title_name div div button{
    box-sizing: border-box;
    padding-top: 0em;
    padding-right: 0.66666667em;
    padding-bottom: 0em;
    padding-left: 0.66666667em;
    min-width: 100px;
    line-height: 2.33333333em;
    border-radius: 0.25em;
}

.dialog_title_name div div .save_workspace_button{
    background: #2E4887; 
    color: white;
    height: 28px !important;
    margin: 0;
    width: 105px;
    font-weight: 100;
    font-size: 0.9rem;
    background: #e0358b;
}

.dialog_title_name div div .save_workspace_button:hover{
    background: #253b6e; 
    color: white;
    transition: 0.5s;
    background: #ba2374;
   }
.dialog_title_name div div .cancel_workspace_button{
    background: #F6F7F9;
    color:#2E4887;
    height: 28px;
    color: white;
    background: transparent;
    border: 1px solid white;
   }
.dialog_title_name div div .cancel_workspace_button:hover{
    background: #e6e7e9;
    color:#2E4887;
    transition: 0.5s;
    color: rgb(207, 198, 198);
    border: 1px solid rgb(207, 198, 198);
    background: transparent;
   }

.dialog_title_name .footer{
    border-top: 0.1px solid silver;   
}
.dialog_title_name .footer_buttons{
    margin-top: 10px !important;
    display: flex;
    justify-content: space-between;
}

.k-grid-header{
    padding: 0 !important;
    color: white !important;
    background: #8892AA !important;
    background: #e0358b !important;
    border-color: #A6ADBF !important;
    border-bottom: 1px solid #5B5859 !important;
}
.k-widget{
    padding: 0 !important;
}

.k-grid-header-wrap{
    padding: 0 !important;
    /*margin-right: 17px;*/
}

th .k-link{
    padding: 7px 12px !important;
    font-size: 0.75rem !important;
}

th .k-header{
    border-color: #A6ADBF ;
}   

.edit_workspace_btn{ color: #2e4887 !important ; border: none !important; background: transparent !important; }


.grid_data div div{
    padding: 0;
}
.grid_data tbody{
    color: black;
}

.add_worspace_dialog .k-dialog {
    min-width: 500px;
    min-height: 220px;
}

.add_worspace_dialog .k-dialog .workspace_modal_heading_text {
    margin-bottom: 50px;
}
.workspace_tab .k-content {
    background: #111;
}
.workspace_tab b {
    color: white;
}
.workspace_tab .information_text {
    color: white;
}
.workspace_tab .k-state-active{
    background-color: #202024 !important;
}
.workspace_tab .k-cell-inner span{

    background-color: #e0358b;
}
.workspace_tab .k-column-title{
    color: white ;
}
.workspace_tab .k-pager-wrap .k-link {
    background-color: transparent;
    color: #e0358b;
}
.k-grid .k-pager-wrap{
    background-color: #111;
}
.add_affiliate_network_dialogue_content .k-widget {
    background-color: transparent;
}
.add_affiliate_network_dialogue_content .new-left-flow{
    background-color: #1d1d20;
    border: 0px;
}
.add_affiliate_network_dialogue_content .new-left-flow .header{
    background-color: #18181bed;
    border-color: #464344;
}
.add_affiliate_network_dialogue_content .k-splitbar{
    background-color: #5B5859;
}
.add_affiliate_network_dialogue_content .header {
    color: white;
    background-color: transparent;
    background-color: #18181bed;
}
.add_affiliate_network_dialogue_content .header .text {
    color: white;
}
.add_affiliate_network_dialogue_content .header  button{
    color: #e0358b;
}
.add_affiliate_network_dialogue_content input{
    background: transparent;
    color: white !important;
}
.add_affiliate_network_dialogue_content .new-right-flow  hr{
    border-color: #5B5859;
}
.add_affiliate_network_dialogue_content  .k-icon{
    color: white;
}
.add_affiliate_network_dialogue_content .radio-buttons .buttons:checked:focus{
    border-color: #e0358b !important;
    color: #ffffff;
    background-color: #e0358b !important;
}
.add_affiliate_network_dialogue_content .header  button span{
    color: #e0358b;
}
.add_affiliate_network_dialogue_content .default-path{
    background-color: #18181bed;
}
.add_affiliate_network_dialogue_conten .new-path .new-path-content {
    background: #767481 !important;
}
.grid_data .k-grid{
    border-color: #5B5859;
}
.grid_data .k-grid-header{
    border-color: #5B5859 !important;
    background: #e0358b !important;
}
@media (max-width:505px){
    .Workspaces .k-button {
        font-size: 10px;
    }
    .workspace_tab div div{
       /* font-size: 10px;*/
    }
    .Workspaces .text_left{
        font-size: 10px;
    } 
    .settings_area_container .Workspaces  .k-grid-table .k-master-row:first-child td {
        height: min-content;
    }
    .k-dialog-wrapper .k-dialog{
        width: 90%;
    }
}
@media (max-width:1000px){
    .add_affiliate_network_dialogue_content .k-widget{
        flex-direction: column;

    }
    .add_affiliate_networks_dialogue .k-dialog .k-dialog-content .add_affiliate_network_dialogue_content{
        padding: 0px;
    }
    .add_affiliate_network_dialogue_content .k-p-5 {
        padding: 0px !important;
    }
    .add_affiliate_network_dialogue_content .row{
        flex-direction: row;
    }
}