.statsContainer{
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow-y: scroll;
    background-color: #18181a;
}
.stats-boxes{
    display: flex;
    flex-flow: row wrap;
    padding: .3125em;
    flex: 0 0 auto;
}

.stats-box{
    flex: 1 0 120px;
    margin: .3125em;
    box-sizing: border-box;
    border: 1px solid #5B5859;
}
.stats-box h3{
    padding: .5em 1.42857143em;
    background-color: #e0358b;
    color: #fff;
    font-family: Roboto,Arial,Helvetica,sans-serif;
    font-size: 14px;
    text-align: left;
    margin: 0;
    font-weight: normal;
    border-bottom:2px solid #5B5859;
}
.stats-box div{
    position: relative;
    padding: .58333333em 0;
    text-align: center;
    background-color: #111;
    color: #363636;
    font-family: Roboto,Arial,Helvetica,sans-serif;
    font-size: 24px;
    background: transparent;

}
.stats-value{
    font-weight: 700;
    color: darkgrey;
}
.stats-value-green{
    color: #29af61;
}

.currency-symbol{
    top: -.21428571em;
    margin: 0 .42857143em 0 0;
}
.percentage-symbol{
    top: -.21428571em;
    margin: 0 0 0 .42857143em;
}
.currency-symbol, .percentage-symbol{
    position: relative;
    font-size: 14px;
    color: #cacaca;
}
.action-controls .right{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    padding-right: 5px;
}
.action-controls{
    padding: .5em 0;
}
/*.date-range, .refresh-btn-action, .actions-toolbar{*/
/*    padding-right: .5em;*/
/*}*/
.table-chart-wrapper{
    flex: 1;
}
.stats-table-chart-container{
    display: flex;
    flex-flow: row wrap;
}
.dashboard-table{
    border-top: 1px solid #dce2ed;
    box-sizing: border-box;
    flex: 1 0 auto;
    padding: .625em;
    /* width: 100%; */
    width: 60%;
    border-top: 1px solid #5B5859;

}

.dashboard-table tr:nth-child(even), .dashboard-table tr:nth-child(even) .checkbox-wrapper {
    background-color: #f3f3f3;
    background-color: #222;

    background-color: #18181b;
    /*background: #303036;
    background: #363636;*/
    background: #202024;
    background: #1e1e22;
    background: #1c1c20;

    background: #151518;
    background: #1c1c20;
}

.stats-table-togglers{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}
.toggle-btn-widgets{
    display: inline-block;
    margin: 0 0 .3125em;
}
.toggle-btn-widgets ul{
    list-style: none;
    padding-left: 0px;
}
.toggle-btn-widgets ul li{
    color: #fff;
    border-radius: 7px;
    cursor: pointer;
    display: inline-block;
    margin: 0 4px 0 0;
    padding: .58333333em 1.33333333em;
    font-family: Roboto,Arial,Helvetica,sans-serif;
    font-size: 12px;
    border: 1px solid #e0358b;
}
.selected-tab{
    background-color: #e0358b !important;
    color: #fff !important;
    cursor: pointer !important;
}
.stats-table-data{
    overflow: auto;
}
.stats-table-data .campaigns{
    margin: .3125em 0 .625em;
    width: 100%;
    font-size: 12px;
    border: 1px solid #5B5859;
}
.stats-table-data .countries{
    margin: .625em 0;
    width: 100%;
    font-size: 12px;
    border: 1px solid #5B5859;
}
.stats-table-data .offers{
    margin: .625em 0 0;
    width: 100%;
    font-size: 12px;
    border: 1px solid #5B5859;
}
.stats-table-data table{
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 12px;
}
.stats-table-data table thead{
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}
.stats-table-data table tbody{
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
    text-align: left;
}
.stats-table-data table thead tr{
    color: #fff;
    background-color: #67708B;
    background-color: #e0358b;
    font-family: Roboto,Arial,Helvetica,sans-serif;
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}
.stats-table-data table thead tr th{
    padding: .5em 1.66666667em;
    text-align: left;
    font-style: normal;
    font-weight: normal;
    display: table-cell;
    vertical-align: inherit;
}
.stats-table-data table thead tr th:first-child{
    border-right:1px solid #5B5859 ;
}
.stats-table-data table tbody tr{
    background-color: #fff;
    cursor: pointer;
    background-color: #18181b;
}
.stats-table-data table tbody tr:first-child{
    border-top:2px solid #5B5859
}
.stats-table-data table tbody tr td:first-child{
    /*background-color: #29af61;*/
    width: .83333333em;
    padding: .66666667em .41666667em;
    text-align: center;
    color: #fff;
}
.stats-table-data table tbody tr td:last-child{
    /*color: #29af61;*/
    width: 18%;
    padding: .66666667em 1.58333333em;
    border-left: 1px solid #cacaca;
    border-left: 1px solid #5B5859;
}

.stats-table-data table tbody tr td{
    user-select: none;
    /*white-space: nowrap;*/
    display: table-cell;
    vertical-align: inherit;
    padding: .66666667em .41666667em;
    color: #363636;
    color: white;
    background: #111;
    background: #18181b;
    background: unset;
    border: 1px solid #5B5859;
}
.dashboard-chart{
    display: flex;
    flex-basis: 40%;
    width: 40%;
}
.dashboard-chart-widget{
    display: flex;
    flex: 1;
    width: inherit;
}
.dashboard_chart_container{
    flex: 1;
    padding: 2.5em 1.25em 0;
    border-top: none;
    border-bottom: none;
    margin: 0;
    width: inherit;
}

.refresh_btn {
    background: var(--color_success);
    background: #e0358b;
    color: var(--white);
    border-radius: 4px;
    /*margin-right: 10px;*/
    font-size: 12px;
    line-height: 29px;
    height: 29px;
    margin: 5px;
}

.refresh_btn span {
    margin: -2px 4px 0 -4px;
    width: 16px;
    font-size: 12px;
}

.neutral_td_bg {
    color: #8d8d8d;
    background-color: #c5c5c5;
    background-color: #080808 !important;
    color: #080808;

    background-color:#3c3c3c !important;
    color:#3c3c3c;
}
.neutral_td_bg::after{
    content:"+";
    color: #080808;
    
    color:#3c3c3c;
}
/*.neutral_td_color {*/
/*    color: #8d8d8d;*/
/*    color: #c5c5c5;*/
/*}*/
.plus_td_bg {
    background-color: #29af61;
    color: #fff;
    background-color: #29af61 !important;

}
.plus_td_color {
    color: #29af61 !important;
}
.plus_td_bg::after{
    content:"+";
    color: white;
}
.minus_td_bg {
    background-color: #f34d4d;
    color: #fff;
    background-color: #f34d4d !important;

}
.minus_td_bg::after{
    content:"-";
    color: white;
}
.minus_td_color {
    color: #f34d4d !important;
}
.statsContainer .refresh-btn-action button:hover{
    background: #e0358b;

}


g[style = "opacity: 0.75;"] rect[style="cursor: pointer;"] {
    fill: #2c2c2c  !important;
    color: white;
}

g[style = "opacity: 0.75;"] rect[style="cursor: pointer; fill: rgb(204, 204, 204);"] {
    fill: #2c2c2c  !important;
    color: white;
}
g text {
    fill: white !important;
}
g[style = "opacity: 1;"] rect[style="cursor: pointer; fill: rgb(204, 204, 204);"] {
    fill: #2c2c2c  !important;
    color: white;
}
/*g[style = "opacity: 0.75;"] rect[style="cursor: pointer; fill: rgb(204, 204, 204);"] text {
    fill:white !important;
}

/*g[style = "opacity: 1;"] rect[fill="rgba(0, 0, 0, 0.03)"] {
    fill:black !important;

}




/*g rect [fill="rgba(0, 0, 0, 0.03)"]{
    fill:rgba(0, 0, 0, 0.03);
}*/
/*.dashboard_chart_container .menu_charts_container {
    width: 721px;
}

/*@media  (max-width: 1500px){
    .dashboard_chart_container .menu_charts_container{
        width: 300px;
    }
}*/
.menu_charts_container{
    /*width: 100%;*/
}
@media (max-width:1350px){


    .dashboard_chart_container{
    padding: 2.5em 0em 0;
    }

}
