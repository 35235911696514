.rdrStaticRangeLabel {
  padding: 5px 20px;
}

.time_picker_con {
  display: flex;
  justify-content: space-evenly;
  padding: 2px;
  line-height: 18px;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
}

.time_picker_con .inner span {
  margin-right: 5px;
}

.time_picker_con .inner select {
  border-radius: 3px;
}

.time_picker_con .inner select:active,
.time_picker_con .inner select:hover,
.time_picker_con .inner select:focus {
  border: 1px solid var(--theme_blue_color);
}

.calendar_bottom_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 5px 5px;
}

.calendar_bottom_row select {
  max-width: 300px;
  height: 31px;
  border-radius: 5px;
  color: black;
  color: white;
}

.buttonContainer {
  position: absolute !important;
  float: unset !important;
  /* /* right: 65px !important; */
  right: -8px ​ !important;
}

.applyButton {
  font-size: 15px !important;
}

.cancelBtn {
  padding: 5px 10px;
  cursor: pointer;
  display: inline-block;
  background: white;
  border-radius: 4px;
  border: 1px solid grey;
}
.applyButton{
  background: #e0358b !important;
  border: #e0358b;
}
.cancelBtn{
  color: white ;
  background: transparent;
}
/*.rangecontainer div[style= "color: rgb(245, 245, 245); font-size: 13px; border: 1px solid rgb(245, 245, 245); border-radius: 4px; cursor: pointer; margin: 4px 4px 8px; background-color: rgb(0, 136, 204);"] {
  background: #202024 !important;
  color: #e0358b !important;
  border-color:#202024 !important;
}*/
.rangecontainer div[style= "color: rgb(0, 136, 204); font-size: 13px; background-color: rgb(245, 245, 245); border: 1px solid rgb(245, 245, 245); border-radius: 4px; cursor: pointer; margin: 4px 4px 8px;"] {
  background: #202024 !important;
  color: #e0358b !important;
  border-color:#202024 !important;
}
.rangecontainer div[style= "color: rgb(0, 136, 204); font-size: 13px; border: 1px solid rgb(245, 245, 245); border-radius: 4px; cursor: pointer; margin: 4px 4px 8px; background-color: rgb(245, 245, 245);"] {
  background: #202024 !important;
  color: #e0358b !important;
  border-color:#202024 !important;
}
/*.rangecontainer div[style="color: rgb(245, 245, 245); font-size: 13px; background-color: rgb(0, 136, 204); border: 1px solid rgb(245, 245, 245); border-radius: 4px; cursor: pointer; margin: 4px 4px 8px;"] {
  border: 0px !important;
}
*/
