body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #18181b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.h1 {
    font-weight: 700;
    line-height: 2rem;
    font-size: 1.5rem;
    margin: 1.33333em 0em;
    padding: 0px;
}
.h2 {
    font-weight: 500;
    line-height: 1.25rem;
    font-size: 1rem;
}

.inline_block { display: inline-block; }
.inline_block_imp { display: inline-block !important; }

.white_box_section_title,
.header_title {
    line-height: 44px;
    font-size: 16px;
}
.normal_text {
    font-size: 12px;
    line-height: 15px;
}
:root {
    --black: #000;
    --white: #ffffff;
    --theme_blue_color: #6786DE;
    --theme_blue_light_color: #E8EFFD;
    --theme_dark_blue_color: #2e4887;
    --theme_dark_blue_color_disabled: rgba(46,72,135,0.75);
    --theme_dark_blue_bg_hover: #1f3566;
    --gray_button_bg: #f6f7f9;
    --gray_button_bg_hover: #e1e7ee;
}
.normal_font { font-weight: 400; }
.medium_font { font-weight: 500; }
.bold_font { font-weight: 700; }
.light_font { font-weight: 300; }


.color_black { color: var(--black); }
.color_black_imp { color: var(--black) !important; }
.color_white { color: #fff; }
.color_white_imp { color: #fff !important; }

.text_left { text-align: left; }
.no_border { border: 0px; }
.no_border_imp { border: 0px !important; }

.theme_blue_color { color: var(--theme_blue_color); }
.theme_blue_color_imp { color: var(--theme_blue_color) !important; }
.theme_dark_blue_color { color: var(--theme_dark_blue_color); }
.theme_dark_blue_color_imp { color: var(--theme_dark_blue_color) !important; }

.theme_dark_bg_color { background-color: var(--theme_dark_blue_color); }
.theme_dark_bg_color_imp { background-color: var(--theme_dark_blue_color) !important;background: #e0358b!important;}
.theme_dark_bg_color_disabled_imp { background-color: var(--theme_dark_blue_color) !important;}
.theme_dark_bg_color_disabled_imp_disabled { background-color: var(--theme_dark_blue_color_disabled) !important;}

.gray_button_bg { background-color: var(--gray_button_bg); }
.gray_button_bg_imp { background-color: var(--gray_button_bg) !important; }
.gray_button_bg:hover { background-color: var(--gray_button_bg_hover); }
.gray_button_bg_imp:hover { background-color: var(--gray_button_bg_hover) !important; }

.normal_btn { padding: 7px 10px; }
.theme_dark_btn { border: 1px solid var(--theme_dark_blue_color); }
.theme_dark_btn:hover { background: var(--theme_dark_blue_bg_hover) !important; }

.btn_disabled {}

.__react_component_tooltip {
    max-width: 300px;
}

@media only screen and (min-width: 1301px) and (max-width: 1450px) {
    .dashboard-table,
    .dashboard-chart {
        flex-basis: 50% !important;
        width: 50% !important;
        overflow: hidden;
    }
}
@media only screen and (max-width: 1350px), only screen and (max-device-width: 1350px) {
    .page_menu_bar_top {
        /* background-color: pink; */
    }
    .page_menu_bar .page_menu_bar_top .grouping_dropdowns {
        grid-gap: 6px !important;
        margin-left: 7px !important;
    }
    .page_menu_bar .page_menu_bar_top .grouping_dropdowns .grouping_tags select,
    .date_container .date_display,
    .normal_text,
    .page_menu_bar .page_menu_bar_top .search_and_date_filters .apply_btn,
    .page_menu_bar .page_menu_bar_bottom .right .add_button,
    .action_buttons_title button,
    .page_bottom_menu_button {
        font-size: 9px !important;
        line-height: 12px;
    }
    .page_menu_bar .page_menu_bar_top .search_and_date_filters .apply_btn,
    .page_menu_bar .page_menu_bar_bottom .right .add_button {
        margin-right: 5px !important;
    }
    .search_and_date_filters .search_input .k-multiselect,
    .search_and_date_filters .search_input input {
        width: 140px !important;
    }
    .header_menu_bar .menu_button {
        margin-right: 12px !important;
        font-size: 9px !important;
    }
    .search_and_date_filters .search_input .search_type_selection span {
        padding: 3px;
    }
    .action_buttons_title button, .page_bottom_menu_button {
        font-size: 9px !important;
    }    
    .action_buttons_title button .k-icon, .page_bottom_menu_button .k-icon {
        width: 10px !important;
        height: 10px !important;
        font-size: 10px !important;
    }
    .k-button {
        padding: 3px 6px !important;
    }
    .dashboard-chart-widget {
        display: block;
        width: 100%;
        width: inherit;
    }
    .dashboard-table,
    .dashboard-chart {
        flex-basis: 100% !important;
        width: 100%;
    }
    .add_affiliate_networks_dialogue .k-dialog {
        max-width: calc(100% - 30px) !important;
    }
    .tracking_url_con p {
        font-size: 12px !important;
        line-height: 15px !important;
    }
    .normal_font_p_tu {
        font-size: 12px !important;
        line-height: 15px !important;
    }
}
/* @media only screen and (max-width: 1300px) and (-webkit-min-device-pixel-ratio: 3) {
    .page_menu_bar_top {
    }
    .page_menu_bar .page_menu_bar_top .grouping_dropdowns {
        grid-gap: 6px !important;
        margin-left: 7px !important;
    }
    .page_menu_bar .page_menu_bar_top .grouping_dropdowns .grouping_tags select,
    .date_container .date_display,
    .normal_text,
    .page_menu_bar .page_menu_bar_top .search_and_date_filters .apply_btn,
    .page_menu_bar .page_menu_bar_bottom .right .add_button,
    .action_buttons_title button,
    .page_bottom_menu_button {
        font-size: 9px !important;
        line-height: 12px;
    }
    .page_menu_bar .page_menu_bar_top .search_and_date_filters .apply_btn,
    .page_menu_bar .page_menu_bar_bottom .right .add_button {
        margin-right: 5px !important;
    }
    .search_and_date_filters .search_input .k-multiselect,
    .search_and_date_filters .search_input input {
        width: 140px !important;
    }
    .header_menu_bar .menu_button {
        margin-right: 12px !important;
        font-size: 9px !important;
    }
    .search_and_date_filters .search_input .search_type_selection span {
        padding: 3px;
    }
    .action_buttons_title button, .page_bottom_menu_button {
        font-size: 9px !important;
    }    
    .action_buttons_title button .k-icon, .page_bottom_menu_button .k-icon {
        width: 10px !important;
        height: 10px !important;
        font-size: 10px !important;
    }
    .k-button {
        padding: 3px 6px !important;
    }
    .dashboard-chart-widget {
        display: block;
        width: 100%;
    }
    .dashboard-table,
    .dashboard-chart {
        flex-basis: 100% !important;
        width: 100%;
    }
    .add_affiliate_networks_dialogue .k-dialog {
        max-width: calc(100% - 30px) !important;
    }
} */