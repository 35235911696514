.top_menu_bar {
    box-sizing: border-box;
    padding: 0em 2em 0em 0.33333333em;
    height: 3.66666667em;
    position: relative;
    display: flex;
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    color: #8892AA;
    fill: #8892AA;
    line-height: 1.25rem;
    font-size: 0.75rem;
    background: #fff;
    background: #18181a;
    box-shadow: 0px -1px 5px rgba(0,0,0,0.6);
    border-bottom: 1px solid lightgray;
    border-color: #5B5859;
}
.top_menu_bar > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
}
.top_menu_bar .top_menu_left {
    display: flex;
    align-items: center;
}
.top_menu_bar .top_menu_left a {
    display: inline-block;
}
.top_menu_bar .top_menu_left .top_bar_big_links {
    color: #8892AA;
    text-decoration: none;
    list-style-type: none;
    font-weight: 500;
    padding: 11px 0;
}
a.active {
    color: #6786DE;
}
.top_menu_bar .top_menu_left .top_bar_big_links::after {
    content: "";
    width: 50%;
    height: 2px;
    background: #6786DE;
    position: relative;
    display: block;
    top: 11px;
    left: 25%;
}
.top_menu_bar .top_menu_left .top_bar_big_links:hover {
    color: #6786DE;
    cursor: pointer;
}
.top_menu_bar .top_menu_left a .logo_container {
    width: 50px;
    display: inline-block;
    width: min-content;
    display: flex;
    justify-content: center;
    padding: 15px;
}
.top_menu_bar .top_menu_left a .logo_container img {
    width: 28px;
}
.top_menu_bar .top_menu_right {
    text-align: right;
}
.top_menu_bar .top_menu_right .right_inner {
    display: flex;
    align-items: center;
}
.top_menu_bar .top_menu_right .right_inner a {
    color: #8892AA;
    display: inline-block;
    padding: 0 1.16666667em;
}
.top_menu_bar .top_menu_right .right_inner a .icons {
    font-size: 18px;
    color: #999999;
}
.top_menu_bar .top_menu_right .right_inner a .icons:hover{
    color: white;
    transition: 0.5s ease;
}
.top_menu_bar .top_menu_right .right_inner a .price_span {
    color: #A6ADBF;
    font-size: 10px;
    white-space: nowrap;
    text-transform: uppercase;
    display: inline-block;
}
.top_menu_bar .top_menu_right .right_inner a .price_span span {
    color: #F73A5D;
    font-weight: 500;
    box-sizing: border-box;
    margin-left: 0.66666667em;
}