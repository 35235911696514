.new-user-btn{
    padding: 15px 22px 0;
    
    display: flex;
}
.add_user_btn{
    background: #4063b4; 
    color: white;    
    margin-left: auto;
    background: #e0358b;;
}
.active{
    color: #29af61;
}
.active:hover{
    color: #29af61;
}
.unactive{
    color: #F73A5D;
}
.rejected{
    color: #fcb431;
}
.unactive:hover{
    color: #fc607d;
}
.add_user_btn:hover{
    background: #2c4786; 
    color: white;
    transition: 0.5s;
    background: #ba2374;
}

.add_user_modal {}

.acctype_radio{
    margin-right: 15px;
}

.acctype_radio_con {
    display: flex;
    align-items: center;
    padding: 4px 0px 0px;
}