.row {}
.row .h4 {
    font-size: 14px;
    line-height: 18px;
    color: #000;
}
.row .h4 .bold {
    font-weight: 600;
    min-width: 220px;
    display: inline-block;
}
.row .h4 span.normal {
    font-weight: 400;
    background: #f2f2f2;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}