.new_flow_modal .add_flow_dialog .flow_title_name .k-dialog{ 
    width: 950px;
    height: 600px;
    display: flex;
    border-radius: 5px !important;
}

.flow_title_name .k-header{
    color: black;
    background: white;
    border-style: none;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.flow_title_name .k-content{
    padding: 0px ;
}
.flow_title_name .k-dialog-title{
    display: block;
    text-align: center;
    line-height: 1.6875rem;
    font-size: 1.25rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 500;
    
}

.flow-heading{
    font-size: inherit;
    text-align: center;
    font-weight: 500;
    box-sizing: border-box;
    padding-right: 0.66666667em;
    padding-bottom: 0.66666667em;
    padding-left: 0.66666667em;
    margin: 0em;
}
.flow-heading .text{
    line-height: 1.6875rem;
    font-size: 1.4rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.feedback{
    box-sizing: border-box;
    margin: em em;
}
.bar-initialized .article-link, .give-feedback-link{
    color: black !important;
    font-size: 12px !important;
    line-height: 1.25rem !important;
    font-size: 0.75rem !important;
    font-family: Roboto, Arial, Helvetica, sans-serif !important;
    padding-left: 2.66666667em;
} 
.bar-initialized {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
    height: 0;
    border-width: 0;
    box-sizing: border-box;
    padding-right: 2.66666667em;
    padding-left: 2.66666667em;
    border-width: 1px 0px;
    height: 2.66666667em;
    border-style: solid;
    border-color: #C3CFDE;
    background-color: #FCFCFD;
    

    
}
.dialog_title_name{
    border: none;
}

.flow_title_name div div .save_workspace_button{
    background: #2E4887; 
    color: white;
    height: 28px !important;
    margin: 0;
    width: 105px;
    font-weight: 100;
    font-size: 0.9rem;
    border-radius: 5px !important;
}

 .text{
    line-height: 1.25rem;
    font-size: 1rem;
    font-weight: 500;
    color: black;
}
.default-path, .traffic-dist, .header{
    background: white;
}
.new-left-flow .header{
    display: flex;
    flex-direction: row;
    position: sticky;
    top: 0;
    z-index: 1;
    align-items: center;
    background: #FFFFFF;
    box-sizing: border-box;
    padding-right: 2.66666667em;
    padding-left: 2.66666667em;
    border-width: 1px 0px;
    height: 3.66666667em;
    border-style: solid;
    border-color: #E1E7EE;
    margin-top: 20px !important;
    width: 100%;
    justify-content: space-between;
}
.flow-container{
    display: flex;
    
}
.new-left-flow {
    display: block;
    position: relative;
    overflow: visible;
    flex: 1;
    background-color: #F6F7F9;
    min-width: 400px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    padding-top: 0.33333333em;
    padding-bottom: 165px;
    border-right: solid #F6F7F9;
}

.new-right-flow{
    display: block;
    position: relative;
    overflow: visible;
    padding: 32px;
    width: 100%;
    height: 100%;
    color: black;
    color: white;
}

.new-right-flow label{
    display: flex;
    font-weight: 500;
    line-height: 1.25rem;
    font-size: 1rem;
    box-sizing: border-box;
    margin-bottom: 0.5em;
    
}

.flow-footer{
    position: sticky;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    min-height: 3.66666667em;
    overflow: hidden;
    background-color: #FFFFFF;
    background-color: #1e1e20;
    z-index: 2;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}


.select-input{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.row {
    display: flex;
}
.block{
    display: block;
}

.offer-redirect,.path-destination label{
    box-sizing: border-box;
    margin-left: 1.66666667em;
    line-height: 0.75rem;
    font-size: 0.75rem;
    font-weight: 100;
    margin-bottom: 0px !important;
}
.path-offer-redirect, .path-destination{
    display: block;
}
.path-offer-redirect label{
    box-sizing: border-box;
    margin-left: 1.66666667em;
    line-height: 0.75rem;
    font-size: 0.75rem;
    font-weight: 100;
    margin-bottom: 0px !important;
}
.new-right-flow hr{
    border-color: #FFFFFF;
    box-sizing: border-box;
    margin-bottom: 1em;
}

.select-input .country-select,.unique-name-input{
    justify-content: space-between;
    /* padding-right:10px; */
}
.select-input .unique-name-input Input{
    border-radius: 4px;
}
.unique-name-input Input{
    width: 100%;
}

.flow-workspace .select-workspace{
    -webkit-appearance: none !important; 
    border-radius: 3px;
    width: 100%;
    height: 30px;
    outline: 0;
    border: 1px solid #5B5859;
    background-color: transparent;
    box-shadow: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    box-sizing: border-box;
    padding: 0 23px 0 7px;
    border-width: 1px;
    line-height: 24px;
    display: block;
	flex: 1;
    font-size: small;
    color:white;
}
.flow-workspace .select-workspace option {
    background: #121214;
    color: white;
}
.flow-workspace .select-workspace:hover,.flow-workspace .select-workspace:focus{
    border: 1px solid #e0358b;
    transition: 0.5s ease;
}
.select-input .select-country{
    -webkit-appearance: none !important; 
    border-radius: 3px;
    outline: 0;
    border: 1px solid #5B5859 ;
    background-color: transparent;
    box-shadow: none;
    padding: 0 23px 0 7px;
    height: 30px;
    border-width: 1px;
    line-height: 24px;
    flex: 1;
    width:100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    color: white;
}
.select-input .select-country:hover,.select-input .select-country:focus{
    border-color: #e0358b;
    transition: 0.5s ease;
}
.select-input .select-country option{
    background-color: #121214;
    color: white;
}
.flow-workspace{
    box-sizing: border-box;
	width: 100%;
    margin-right: -50px;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    position: relative;
}

.flow-workspace span{
	top: 6px;
    left: -20px;
}

.country-select{
    width: 200px;
}

/* .country-select span{
    left:-20px;
} */

.arrow-span-icon{
    color: #2E4887;
    color: #5B5859;
    font-size: 15px;
    top: -25px;
    left: 176px;
}


.switch{
    display: flex;
    flex-direction: row;
    padding-left: 2.66666667em;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
}

.transparent-btn{
    justify-content: right;
    box-sizing: border-box;
    /* margin-left: 0.66666667em; */
    color: #6786de;
    color: #e0358b;
	cursor: pointer;
	background: Transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;
    outline:none;
    margin: 0%;
}
.transparent-btn span{
    color: #e0358b !important; 
}
.m-l-c:hover{
    margin-left: auto;
}
.m-l-c{
    margin-left: auto;
}
.transparent-btn:hover{
    /* justify-content: right; */
    box-sizing: border-box;
    color: #314a8f;
    color: #e0358b;
	cursor: pointer;
	background: Transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;
    outline:none;
    margin: 0%;
    margin-left: auto;
 
}

.switch .k-switch-container{
    font-size: 0.45rem !important;
    margin-right: 5px;
    background-color: #C3CFDE;
}

.switch .k-switch-on .k-switch-container  {
    background-color: #29AF61 !important;
    background-color: #e0358b !important;
}
.optimization-switch{
    margin-top: 10px;
    padding-bottom: 15px;
}
.new-path{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    width: 100% !important;
}
.new-path .new-path-content{
    padding-right: 2.66666667em;
    padding-left: 5.33333333em;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}
.new-path .path-input{
    width: 80px;
    margin-right: 0 !important;
}
.flow-footer .footer_buttons{
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
}
.flow-footer .footer_buttons .button {
    box-sizing: border-box;
    width: 8.33333333em;
    margin-bottom: 0em;
    
}
.flow-footer .footer_buttons .cancel_flow_button {
    background: #f6f7f9;
    color: #2e4887;
    color:white;
    background: transparent !important;
    border: 1px solid white;

}
.flow-footer .footer_buttons .cancel_flow_button:hover{
    background: #e1e7ee;
    color: #2e4887;
    transition: 0.5s;
    background: transparent !important;
    color: rgb(207, 198, 198);
  border: 1px solid rgb(207, 198, 198);

}
.flow-footer .footer_buttons .save_flow_button {
    background: #2e4887;
    color: #fff;
    background-color: #e0358b !important;
    border: 1px solid #e0358b;

}
.new-path-content .copy, .new-path-content .remove{
    width:100px !important;
    color: #2e4887;
}
.flow-footer .footer_buttons .save_flow_button:hover{
    background: #1f3566;
    color: #fff ;
    transition: 0.5s;
    background-color: #ba2374!important;
    border: 1px solid #ba2374;

}
.new-path  .name .k-icon {
    color: #2e4887;
}
.new-path .name{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100% !important;
}
.new-path .name .name-span{
    margin-right: 10px;
}

.selected{
    background: #E8EFFD !important;
}

.new-path:hover,.new-flow-text:hover {
    cursor: pointer;
    background: #E8EFFD ;
}

.push{
    margin-left: auto;
}


.rule-based-condition-heading{
    margin: 0px;
    font-size: inherit;
    font-weight: normal;
    box-sizing: border-box;
    color: #A6ADBF;
    
}

.new-rule-based{
    align-items: center;
    border-width: 0px 0px 1px;
    border-color: #C3CFDE;
    border-style: solid;
}
.conditions{
    box-sizing: border-box;
    padding-right: 32px;
    padding-left: 32px;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 350px;
}
.conditions-data{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #8892ab;
    
}
.conditions-data .header{
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.condition-name-green{
    box-sizing: border-box;
    height: 20px;
    margin-right: 7px;
    padding: 0px 5px 0px 5px;
    font-weight: bold;
    border-radius: 16px;
    background-color: #daf6e6;
    color: #29af61;
}
.rule-name-green{
    color: #29af61;
}
.rule-name-red{
    color: #f73a5d;
}
.condition-name-red{
    box-sizing: border-box;
    height: 20px;
    margin-right: 7px;
    padding: 0px 5px 0px 5px;
    font-weight: bold;
    border-radius: 16px;
    background-color: #ffe8ed;
    color: #f73a5d;
}
.condition-active{
    box-sizing: border-box;
    margin-left: 0px;
    background-color: #e8effd;
    min-height: 20px;
    padding: 5px;
    display: flex;
    position: relative;
    align-items: flex-start;
}

.conditions-data-show{
    margin-top: 1.3em;
    background: #35353a;
}
.payout_input{
    width: 90px;
    /* height: 28px; */
    border:none;
}
.payout_input:focus{
    border-color: #6786de;
}

.payout_input:disabled{
    background: #f6f7f9;
    border-color: #c3cfde;
    color: #a6adc0;
    background: transparent;
}
.payout_input::-webkit-outer-spin-button,
.payout_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.payout_form{
    display:flex;
    flex-direction:row;
    border:1px solid grey;
}

.payout_input:focus {
    outline: none ;
  }
.path-weight-input,.weight-input{
    width: 100px;
}

.blue-icon{
    color: #2e4887;
    margin-left: 6px;
    cursor: pointer;
}
.default-path .selected {
    background: #76748146 !important;
}
.default-path .new-path:hover {
    background: #76748146 !important;
}
.default-path .new-path {
    background: #121214!important;
}
.new-path-content  {
    color: white;
}
.selected{background: #76748146 !important;}
.add_affiliate_network_dialogue_content .notes  p{
    color: white;
}
.add_affiliate_network_dialogue_content .rule-collapse-child div{
    color: rgba(255, 255, 255, 0.466) !important;
}
.add_affiliate_network_dialogue_content .rule-collapse-child button{
    color: #e0358b ;
}
.add_affiliate_network_dialogue_content .rule-collapse-child button span{
    color: #e0358b ;
}
.new-path-content:hover{background: #76748146 !important;}
.default-path .selected{background: #76748146 !important;}

@media (max-width:630px){
    .row {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .profile_row .container .col-md-6,.profile_row .container .col-md-12 {
        width: 100% !important;
    }
}