hr{
	box-sizing: border-box;
	margin-bottom: 1.3em;
	margin-left: 1.3em;
	margin-right: 1.3em;
    border-width: 1px 0px 0px;
    border-color: #F0F3F7;
    border-style: solid;
    margin-top: 0px;
    border-color:#5B5859 ;
}

.h2-text div{
	line-height: 1.25rem;
    font-size: 1rem;
    overflow: hidden;
    white-space: nowrap;
	text-overflow: ellipsis;
	text-align: left;
	font-weight: normal;
	padding-left: 22px;
    padding-right: 22px;
}

.h2-text{
    margin-bottom: 10px;
}
	


.auto-complete{
	box-sizing: border-box;
	width: 383px;
	margin-left: 22px;
    margin-right: 22px;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    position: relative;
}

.auto-complete select{
    -webkit-appearance: none !important; 
    border-radius: 3px;
    width: 383px;
    height: 26px;
    outline: 0;
    border: 1px solid #2e87fc ;
    background-color: transparent;
    box-shadow: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: text;
    box-sizing: border-box;
    padding: 0 23px 0 7px;
    border-width: 1px;
    line-height: 24px;
    display: block;
	flex: 1;
	width: 383px;
	height: 26px;
	font-size: small;
}

.auto-complete option:hover{
    cursor: pointer !important;
}

.auto-complete span{
	color: #2E4887;
	top: 5px;
    left: -20px;
	font-size: 15px;
}

input::-webkit-calendar-picker-indicator {
	display: none;
  }

  .url-text{
	flex: 1;
    font-family: Helvetica, Arial, sans-serif;
    color: #A6ADBF;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
	position: relative;
	text-align: left;
	
  }
  .click-url{
	margin: 0.66666667em 1.33333333em 0em;
    box-sizing: border-box;
    display: flex;
	border-bottom: 2px solid #5B5859;
    width: auto;
    margin-top: 0px;
  }
  .click-url1{
	margin: 0.66666667em 1.33333333em 0em;
    box-sizing: border-box;
    display: flex;
	border-bottom: 2px solid #5B5859 ;
    width: 50%;
    margin-top: 0px;
  }

  .h2-text-p{
	overflow: hidden;
    white-space: nowrap;
	text-overflow: ellipsis;
	text-align: left;
	font-weight: normal;
	color: black;
  }
.learn-link, .radio-buttons, .script{
	font-size: 0.75rem ;
}

.learn-link:hover{
	text-decoration: none;
	color: #4a63a8;
}

.radio-buttons, .script{
	text-align: left;
	padding-left: 22px;
	display: block;
}
.script{padding-bottom: 10px;}
.radio-buttons .buttons:checked {
    border-color: #6786de !important;
    border-color: #e0358b !important;
    color: #ffffff;
	background-color: #6786de !important;
    background-color: #e0358b!important ;
}
.radio-buttons .buttons:checked:focus {
    border-color: #6786de !important;
    color: #ffffff;
	background-color: #6786de !important;
}

.lander-type-container, .message-container, .container-information{
	padding: 22px;
	font-size: 0.75rem ;
	text-align: left;
	display: block;
}


.lander-type-container textarea{
	box-sizing: border-box;
    min-height: 20em;
    width: 90%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 3px 0 7px;
    outline: 0;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
	border: 1px solid #c3cfde;
	margin-top: 10px;
	padding-top: 10px;
	color: #a6adc0;
	background-color: #f6f7f9;
    resize: none;
    

}

.message-container{
	background-color: #eaf1f9;
	padding: 15px; 
	border-left:2px solid #6786de;
}

.information{
	
	display: flex;
}
.information .k-icon{	
	display: flex;
	color: #6786de; 
	padding-right: 10px;
	text-decoration: none;
}

#textarea, #textarea1, #textarea2,#textarea3,#textarea4,#textarea5,#textarea6{
	border: none;
	border-bottom: 1px solid;
	resize: none;
	flex: 1;
    font-family: Helvetica, Arial, sans-serif;
    color: #A6ADBF;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
	position: relative;
	text-align: left;
	border: none;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
	box-shadow: none;
	height: 19px;
    background: transparent;
    color: rgb(219, 214, 214);

}

.copy-url-btn{
	box-sizing: border-box;
    margin-left: 0.66666667em;
    color: #6786DE;
	cursor: pointer;
	background-color: Transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;
    outline:none;
    color:#e0358b ;
}
.settings_area_container p {
    color: white;
}
.settings_area_container h2 {
    color: white;
}
.settings_area_container select{
    color: white;
    border-color: #5B5859;
}
.settings_area_container span{
    color: rgb(155, 154, 154);
}
.settings_area_container .arrow-up-down span{
    color: #e0358b;
}
@media (max-width:420px){
    .auto-complete{
        width: 90%;
    }
}
.settings_area_container select option{
    color: white;
    background-color: #222;
}