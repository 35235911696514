.card{
    background: white;
    width: 600px;
}

#userName, #lastName{
    padding: 20px;
    border: 1px solid;
    border-radius: 5px;
}

.registration_success_para {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    padding: 10px;
    margin: 15px 0 2px;
    border-radius: 4px;
}

.registration_failure_para {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    padding: 10px;
    margin: 15px 0 2px;
    border-radius: 4px;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
body{
    margin: 0;
    padding: 0;
}

.page_container {
    height: 100vh;
    width: 100vw;
    overflow-x: auto;
    overflow-y: auto;
    background: #111;
    width: 100%;
}
.page_container .form_page_logo_container {
    display: block;
    position: relative;
    width: 100%;
    overflow: auto;
    text-align: left;
    padding: 40px 40px 0px 40px;
}
.page_container .form_page_logo_container .inner {
    display: flex;
    align-items: center;
}
.page_container .form_page_logo_container img {
    width: 50px;
    display: inline-block;
}
.page_container .form_page_logo_container h1 {
    display: inline-block;
    padding: 0;
    margin: 0 0 0 15px;
    border: 0px;
    line-height: 100%;
    font-family: 'Montserrat', sans-serif;
    /*font-family: ;*/
}

.page_container .container{
    padding: 20px;
}

.page_container .container form {
    max-width: 750px;
    margin: 2% auto 0;
    padding: 40px 30px 30px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid rgb(24, 24, 27);
    box-shadow: 0px 2px 4px rgba(0,0,0,0.1);
    transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    -webkit-transition: all 0.6s ease;
    background: rgb(24, 24, 27);

}

.page_container .container form:hover {
    box-shadow: 0px 2px 20px rgba(0,0,0,0.3);
}

.login_h2 {
    margin-top: 0;
    padding: 0 0 20px 0;
    color: hsla(0,0%,100%,.85);
    border-bottom: 1px solid black;
    font-family: 'Montserrat', sans-serif;
}

.login_links {
    color: #e0358b;
    text-decoration: none;
    font-size: medium;
}
.login_links:hover{
    transition: .5s;
    text-decoration: underline;
}

.forget-pass-btn{
    width: 55%;
    height: 40px;
    border: 1px solid;
    background: #2980b9;
    border-radius: 25px;
    font-size: 18px;
    color: #e9f4fb;
    font-weight: 700;
    cursor: pointer;
    outline: none;
    margin: 20px auto;
}

.forget-pass-btn:hover{
    background: #2980b9;
    transition: .5s;
    color: #e9f4fb;
}

.signup_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
}
.sign_up_birthday_con {
    position: relative;
}
.sign_up_birthday_con .inner {
    display: flex;
    position: relative;
    /*padding-top: 20px;*/
    align-items: center;
    grid-column-gap: 15px;
}
.sign_up_birthday_con label {
    min-width: 70px;
}
.sign_up_birthday_con .birthday_show {
    cursor: pointer;
    border: 1px solid #000;
    padding: 0px 8px;
    border-radius: 5px;
    width: 100%;
    height: 36px;
    line-height: 36px;
}
.sign_up_birthday_con .birthday_calendar {
    position: absolute;
    top: 100%;
    background: #fff;
    z-index: 10000000;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.1);
}
.black_bg_signup {
    background: rgba(0,0,0,0.2);
    width: 100%;
    height: 100vh;
    z-index: 10000;
    position: absolute;
}
.signup_label {
    color: #adadad;
}
.acc_type_row {
    display: block;
    text-align: left;
    padding-top: 15px;
}
.acc_type_row ul {
    display: flex;
    grid-column-gap: 15px;
}
.acc_type_row label {}
/* button[type="button"]:hover{
    border: 1px solid;
    border-radius: 25px !important;
    transition: .5s !important;
    background: white !important;
    outline: none !important;
    color: black !important;
}
button[type="button"]{
    width: 50%;
    border: 1px solid !important;
    border-radius: 25px !important;
    outline: none !important;
    outline-style: none !important;
    background: white !important;
    font-size: 15px !important;
    transition: .5s !important;
} */
/* .googleLogin>div{
    margin-left: 30px;
    outline: none !important;
    font-weight: 700 !important;
}

.googleLogin>span{
    outline: none !important;
    font-weight: 700 !important;
} */



.form_element {
    max-width: 500px;
    margin: 0vh auto 0;

}
#email,
#password,
#confirmPassword,
#re-password,
#linkedin,
#facebook,
#website,
#streetAddress,
#city,
#state,
#zipCode,
.signup_simple_input {
    padding: 20px;
    border: 1px solid;
    border-radius: 5px;
}
.address_con {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
}
.address_con .country_dropdown {
    width: 100%;
    /*margin-top: 20px;*/
    padding: 7px !important;
    border-radius: 5px !important;
    font-size: 13px !important;
    line-height: 17px !important;
}
.address_con .country_dropdown:focus{
    border: 1px solid #e0358b !important;
}

/* .k-form-error{
    font-size: small;
    color: tomato;
    font-weight: 400;
} */
.k-label{
    font-size: medium;
    color: #adadad;
}
k-textbox-container k-state-empty{
    height: 200%;
    width: 0;
}

.signinbutton{
    width: 50%;
}


.separator {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    margin: 20px 0;
}

.separator > span {
    position: relative;
    display: inline-block;
}

.separator > span:before,
.separator > span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: rgba(0, 0, 0, 0.35);
}

.separator > span:before {
    right: 100%;
    margin-right: 15px;
}

.separator > span:after {
    left: 100%;
    margin-left: 15px;
}

.react-tel-input .country-list {
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.signup_grid,
.acc_type_row {
    width: 100%;
    /*max-width: 690px;*/
    box-sizing: border-box;
    text-align: left;
}
.react-tel-input .flag-dropdown,
.react-tel-input .form-control {
    border: 1px solid #000;
}
/*.react-tel-input {*/
/*    margin-top: 20px;*/
/*}*/
.react-tel-input .form-control {
    width: 100%;
    padding-left: 44px !important;
    /*height: 42px;*/
}
#email:focus{

    border: 1px solid #e0358b !important;

}
.react-tel-input .form-control{
    border: 1px solid #5B5859!important;
    color: white;
    background: none;
    outline: none;
    transition: 0.5s all ease;
}
.react-tel-input .form-control:focus{
    border: 1px solid #e0358b !important;
}
.react-tel-input .flag-dropdown {
    background-color: inherit !important;
    border: 0px solid #000
}
.react-tel-input .flag-dropdown .open {
    background-color: inherit;
    border: 0px solid #000
}
.react-tel-input .flag-dropdown.open .selected-flag{
    background: none;
    border: 0px solid #000
}
.react-tel-input .flag-dropdown.open .selected-flag{
    background-color: inherit;
}
.react-tel-input .selected-flag:hover,.react-tel-input .selected-flag:focus{
    background-color: inherit;
}
.react-tel-input .country-list{
    background-color: #111;
}
#email{
    background: none;
    color: white;
    border: 1px solid #5B5859!important;
    outline: none;
    border: 0px;
}
#password {    background: none;
    color: white;
    border: 1px solid #5B5859!important;
    outline: none;
    border: 0px;
}
#password:focus{
    border: 1px solid #e0358b!important
}
#confirmPassword:focus{
    border: 1px solid #e0358b!important
}
.forget-pass-btn{
    border: 1px solid #e0358b!important;
    background:#e0358b;
}
.forget-pass-btn:hover{
    background: #ba2374;
    border-color:#ba2374;
}
.react-tel-input .country-list .country:hover{
    background-color:#2b1825 ;
}
.react-tel-input .country-list .country.highlight{
    background-color:#2b1825 ;
}
.react-tel-input .country-list{
    color:#d4d0d1 ;
}
.register_form_element option::selection{
    background:#d4d0d1 ;
}
.register_form_element select:focus{
    border: 1px solid #e0358b!important
}
.register_form_element select{
    transition: 0.5s all ease;
}
.k-text-error,.k-form-error{
    color: #a81f27 !important;
}
.k-textbox::selection, .k-textbox .k-input::selection{
    background-color: #e0358b !important;
}
input[type=text]::selection{
    background-color: #e0358b !important;
}
#email::selection{
    background-color: #e0358b !important
}
.react-tel-input .form-control::selection{
    background-color: #e0358b !important
}
.page_container{
    background: rgb(24, 24, 27);
}
.k-radio{
    background-color: inherit;
    border-color: #5B5859;
}
@media (max-width:781px){
    .signup_grid,.address_con{
        grid-template-columns: 1fr;
    }

}
@media (max-width:425px){
    .page_container .container form{
    padding: 40px 0px 30px ;
}
}
@media (max-width:360px){
    .forget-pass-btn{
        font-size: 14px;
    }
    .login_h2 {
        font-size: 1.3em;
    }
}
@media(max-width:298px){
    .login_h2 {
        font-size: 1.1em;
    }
    .forget-pass-btn{
        font-size: 12px;
    }
}
@media (max-width:397px){
    .k-form{
        font-size: 12px;
    }
    .k-form a{
        font-size: 14px;
    }
}
@media(max-width:352px){
    .k-form{
        font-size: 9px;
    }
    .k-form a{
        font-size: 11px;
    }
}
@media (max-width:317px){
    .k-form{
        font-size: 12px;
    }
    .k-form a{
        font-size: 15px;
    }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: white;
}