.profile_row {
    text-align: left;
}
.profile_row .container {
    /*margin-bottom: 10px;*/
    /*border-bottom: 1px solid #f2f2f2;*/
}
.profile_row .container .col-md-12,
.profile_row .container .col-md-6 {
    padding: 10px;
    width: 100%;
}
.profile_row .container .col-md-12 label,
.profile_row .container .col-md-6 label {
    font-size: 14px !important;
    font-weight: 500;
    color: var(--theme_blue_color);
    color: #f2f2f2;
    line-height: 18px;
}
.profile_row .container .col-md-12 div,
.profile_row .container .col-md-6 div {
    /*border: 1px solid #253b6e;*/
    /* min-height: 30px; */
    line-height: 22px;
    border: 1px solid #5B5859;
    padding: 2px 7px;
    border-radius: 4px;
    color:#fff;
}
.profile_row .container .col-md-12 div span,
.profile_row .container .col-md-6 div span {}