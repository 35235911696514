.last_switches_row textarea {
    width: 100%;
    height: 150px;
    border-radius: 4px;
    background-color: #f6f7f9;
    border: 1px solid #ccc;
    padding: 10px;
    
}
.last_switches_row {
    flex-direction: column;
}

.last_switches_row p {
    padding: 10px !important;
}
.last_switches_row p.one {
    padding: 0px !important;
}

.success_msg_con {
    margin: 20px 0;
}
.success_msg_con div,
.success_msg_con p {
    width: 100%;
}

.lander_name_country_tag {
    display: inline-block;
    padding-left: 5px;
    padding-right: 2px;
    padding-top: 5px;
    line-height: 16px;
    color: #000;
    position: absolute;
    top: 5px;
}

.lander_name_ts_tag {
    display: inline-block;
    padding-left: 0px;
    padding-right: 2px;
    padding-top: 5px;
    line-height: 16px;
    color: #000;
    position: absolute;
    top: 5px;
}

.intermediate_click_urls_group {
    margin-bottom: 10px;
}

.add_affiliate_network_dialogue_content .copy_group .input_container input {
    width: calc(88% - 10px);
}
.add_affiliate_network_dialogue_content .copy_group .input_container .tags,
.add_affiliate_network_dialogue_content .copy_group .input_container .copyTags {
    width: 12%;
}

.campaign_modal_tabs {
    display: flex;
    justify-content: space-around;
    align-items: center;
    transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    -webkit-transition: all 0.6s ease;
    cursor: pointer;
    border-bottom: 1px solid var(--color_cta_buttons_bg_light_gray);
}
.campaign_modal_tabs .tab {
    width: 100%;
    border-top: 1px solid var(--color_cta_buttons_bg_light_gray)
}
.campaign_modal_tabs .tab:hover,
.campaign_modal_tabs .tab:focus,
.campaign_modal_tabs .tab:active {
    background-color: #f2f2f2;
}
.campaign_modal_tabs .active {
    color: var(--theme_blue_color);
    border-top: 1px solid var(--theme_blue_color);
    border-right: 1px solid var(--color_cta_buttons_bg_light_gray);
    box-shadow: 0px 2px 4px rgba(0,0,0,0.16);
}
.campaign_modal_tabs .disabled {
    cursor: no-drop;
    background-color: #f2f2f2;
    width: 100%;
    border-top: 1px solid var(--color_cta_buttons_bg_light_gray)
}

.destination-combobox{
    width: 100%;
}

.l-h-r{
    line-height: 2em;
}

.campaign_notes {
    width: 100%;
    height: 130px;
    padding: 10px 5px;
    font-size: 12px;
    border: 1px solid var(--light_grey_border_color);
    border-radius: 5px;
}

.campaign_notes:active,
.campaign_notes:focus {
    outline: none;
}

.notes_h3 {
    margin: 0px 0 5px 0;
    padding: 10px 0 0 0;
    color: var(--theme_blue_color) !important;
    font-size: 1em;
}