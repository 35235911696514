.delete_domain_text {
    font-size: 17px;
    line-height: 30px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px 10px 4px 10px !important;
    margin: 0px;
}

.cancel_button {
    margin-right: 10px;
}
.settings_add_custom_domains_form .row {
    display: flex;
    grid-gap: 20px;
    align-items: center;
}
.settings_add_custom_domains_form .row label {
    min-width: 100px;
}

.delete_domain_form {
    display: flex;
    justify-content: space-between;
    height: 100%;
}