.header_menu_bar {
    background: #fff;
    padding: 8px 20px 6px;
    display: block !important;
    align-items: center;
    justify-content: flex-start;
    text-align: justify;
    border-bottom: 1px solid lightgray;
}
.header_menu_bar .menu_button {
    color: #8892AA;
    background: transparent !important;
    border: 0px;
    margin-right: 20px;
}
.header_menu_bar .menu_button:hover,
.header_menu_bar .activeButton,
.header_menu_bar .menu_button_report:hover {
    background: #6786DE !important;
    color: #FFFFFF;
}
