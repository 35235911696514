


a{margin:0;padding:0;font-size:100%;vertical-align:baseline;background:transparent;text-decoration:none}

* {
  box-sizing: border-box;
}


/* =============================================================================
   My CSS
   ========================================================================== */

/* ---- base ---- */







/* ---- stats.js ---- */


/* ---- particles.js container ---- */


.nav-bar .logo_con {
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 10;
  top: 0;
}

.nav-bar .logo {
  max-width: 400px;
  position: absolute;
  z-index: 10;
  top: 50vh;
  left: 50%;
  transform: translate(-50%,-50%);
  -moz-transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
}
.nav-bar .logo img {
  width: 100%;
}

.nav-bar header {
  /*background-color: #b61924;*/
  background-color: rgba(255,255,255,0);
  /*box-shadow: 0px 2px 4px rgba(255,255,255,0.5);*/
  border-bottom: 1px solid #797979;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -webkit-transition: all 0.8s ease;
  background-color: black;
  background-color: #18181b;
}
.nav-bar header nav {
  padding: 0px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  /*grid-gap: 20px;*/
}

.nav-bar .transition_small {
  transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -webkit-transition: all 0.6s ease;
}
.nav-bar header nav a {
  color: #fff;
  font-size: 18px;
  padding: 15px 25px;
}
.nav-bar header nav a:hover {
  background-color: #fff;
  /* color: #b61924; */
  color: #000;
}

.nav-bar section {
  width: 100%;
  padding: 100px 0;
}

.nav-bar section .inner {
  max-width: 1024px;
  margin: 0 auto;
}

.nav-bar #what-we-do {
  background-color: #fff;
}
.nav-bar #what-we-build,
.nav-bar #jobs {
  background-color: #000;
  color: #fff;
}
.nav-bar #who-we-are {
  color: #fff;
}

.nav-bar header .icon {
  display: none;
  align-items: center;
  width: 100%;
  height: 50px;
  text-align: right;
  padding: 0 15px;
  justify-content: flex-end;
  border-bottom: 1px solid #797979;
}
.nav-bar header .icon .inner {
  width: 40px;
  height: auto;
  background: #000;
  cursor: pointer;
  overflow: hidden;
  background: #18181b;
}
.nav-bar header .icon .inner .line {
  width: 100%;
  height: 2px;
  background: #fff;
  display: block;
  margin: 5px 0;
}
.nav-bar header .icon .inner .line_second {
  margin-left: 20px;
}
.nav-bar header .icon .inner .line_second:hover,
.nav-bar header .icon .inner .line_second:active,
.nav-bar header .icon .inner .line_second:focus {
  margin-left: 0px;
}

.nav-bar .nav-hidden-logo{
  display: none;
  margin: 0 auto;
}
.nav-bar .hidden-box-nav{
  width:40px;
}
.nav-bar .sign-up-btn{
  background-color: dimgray;
  margin: 0 10px;
  background-color: transparent;
  border-color:#797979 ;
}
.nav-bar #flex-nav{
  justify-content: space-between;
}
.nav-bar #header-logo{
  background-color: transparent;
  width: 320px;;
 display: flex;
 justify-content: center;
 justify-content: flex-start;
}
.nav-bar .signinbutton{
  width: 150px;
}
.nav-bar .sign-up-btn{
  width: 150px;
}
.nav-bar .sign-up-btn{
  background-color: #e0358b;
  border-color: #e0358b;

}

.nav-bar #sign-up-btn:hover,.header #sign-up-btn:hover{
  /*background: #312f2f;*/
  background: transparent;
  background: #27272b;
  color: white;
  /*color: #b1b1b1;
  border: 1px solid #b1b1b1;*/
  background-color: #ba2374;
  border-color: #ba2374;
}
#sign-up-btn{
  font-size: 14px;
}
.nav-bar #sign-in-btn{
  font-size: 14px;

}
.nav-bar #sign-in-btn{
  background: transparent;
  border-color: #797979;
}

.nav-bar #sign-in-btn:hover,.header #sign-in-btn:hover{
  background: #ba2374;
  border-color: #ba2374;
  color: white;
  border-color: #797979;
  background: #27272b;
}
.nav-bar .transition_small:hover{
  text-decoration: underline;
}
.nav-bar .mobile_logo_img{
  width: 120px;
}
.nav-bar #header-logo-Link{
  display: flex;
  justify-content: flex-start;
}
.nav-bar #header-logo-Link:hover{
  background: transparent;
  display: flex;
  justify-content: flex-start;
}
.header-content .btns #Request-btn
{
  display: inline-block;
}
.header-content .btns #sign-up-btn{
  display: inline-block;
  width: 157px;
  background: #e0358b;
}
  @media only screen and (max-width: 768px) {
    .nav-bar header nav {
    flex-direction: column;
  }
  .nav-bar header .icon { display: flex; }
  .nav-bar header {
    height: 50px;
    overflow: hidden;
  }
  .nav-bar .header_auto {
    height: 409px !important;
    background: #000;
    z-index: 300;
    background: #18181b;
  }
  .nav-bar section {
    padding-left: 15px;
    padding-right: 15px;
  }
  .nav-bar #header-logo{display:none;}
  .nav-bar .btns{

  }
  .nav-bar #nav-center{
    display: flex;
    flex-direction: column;
  }
  .nav-bar .btns{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2px;
  }
  .nav-bar .btns button,.nav-bar .btns a,.signinbutton {
    margin-bottom: 10px;
  }
    .nav-bar .btns button ,.nav-bar .btns a,.signinbutton{
    margin-bottom: 10px;
  }
  .nav-bar #toggle_icon{
    justify-content: space-between;
  }
}


@media only screen 
and (max-device-width: 768px){
  .nav-bar body{height: min-content;}
  

  .nav-bar #sticky-logo-img{
    position: sticky;
    top: 0px; 
    height: 50px;
  }
}


@media (max-width :768px){
  .nav-bar #sticky-logo-img{
    position: sticky;
    top: 0px; 
    height: 50px;
}
.nav-bar header nav a {
  font-size: 18px;
  padding: 15px 25px;
}
.nav-bar .btns button ,.nav-bar .btns a,.signinbutton{
  width: 100px;
}
.nav-bar .sign-up-btn{
  width: 100px;
}

}

@media (max-width: 375px){
  .nav-bar #sticky-logo-img{
    height:40px;
  }

}

@media (max-width:290px){
  .nav-bar .hidden-box-nav{
  width: 10px;
}
}
@media (max-width:768px){
  .nav-bar section{
    padding: 45px 15px;
  }
}
@media(max-width:1232px){
  .nav-bar .signinbutton{
    width: 100px;
  }
  .nav-bar .sign-up-btn{
    width: 100px;
  }
  .nav-bar #header-logo{
    width: 220px;
  }
}
@media (max-width:1031px){

  .nav-bar header nav a{
      padding: 15px 18px;
      font-size:17px;
    }
    .nav-bar .signinbutton {
      width: 80px;
  }
  .nav-bar .sign-up-btn{
    width: 80px;
    
  }
  .nav-bar #header-logo {
    width: 180px;
}
  }
@media (max-width:794px){
  .nav-bar header nav a{
    padding: 15px 12px;
  }
}
@media (max-width:900px){
  .nav-bar header nav a{
    padding: 15px 12px;
    font-size:15px;
  }
}
.nav-bar header nav a:focus{
  color: #fff;
  text-decoration: none;
}
.nav-bar header nav a:hover{
  color: black;

}
