.homepage .pricing-section {
    background: #18181b;
}
.homepage .pricing-section .site-content{
    background: #18181b;
}
.homepage .pricing-section .plans__price-box{


font-family: "Poppins", sans-serif;
font-weight: 300;
letter-spacing: 0.02em;

font-size: 16px;
line-height: 24px;
list-style: none;

margin: 0;
padding: 0;
display: flex;
flex-direction: column;
align-items: center;
color: #ebebeb;
margin-top: 10px;
}
.homepage .pricing-section .plans__price-label {
    font-size: 13px;
    line-height: 19px;
}
.homepage .pricing-section .plans__price-text {
    font-size: 50px;
    line-height: 65px;}
.homepage .pricing-section  .plans__price-text span {
        font-size: 30px;
        line-height: 45px;}
.homepage .pricing-section .plans__price-desc {
    font-size: 13px;
    line-height: 19px;
    font-weight: 300;
}
.homepage .pricing-section .plans__price-desc span {
    font-weight: 400;
}
.homepage .pricing-section .plans__price-box * {
 font-family: "Poppins", sans-serif;
 line-height: 20px;
}
.homepage .pricing-section .plans__title-3 {
    margin: 0 0 5px 0;
    font-size: 40px;
    line-height: 45px;
    color: #ebebeb;
    font-family: "Poppins", sans-serif;
    color: #e0358b;
}
.homepage .pricing-section .desc {
    color: #ebebeb;
}
.homepage .pricing-section .features-page .features {
    margin-top: -5px;
}
.homepage .pricing-section .features-page .features ul.feature-list .item .desc ul li {
    position: relative;
    list-style: none;
}
.homepage .pricing-section .features-page .features ul.feature-list .item .desc ul li p{
    margin: 0px;
    padding: 0px;

}
.homepage .pricing-section .features-page .features ul.feature-list .item .desc ul li p::before{
    width: 17px;
    height: 13px;
    position: absolute;
    content: "";
    top: -1px;
    left: -25px;
    background-image: url(img/stick.svg);
    background-repeat: no-repeat;
}
.homepage .pricing-section .features-page .features ul.feature-list .item .desc ul li p::after {
    width: 0px;
}
@media (max-width: 737px){
    .pricing-section .features-page .features .desc ul li:after {
        width: 0px;
    }
}

@media (max-width: 991px){
.homepage .pricing-section .plans__price-label {
    font-size: 9px;
    line-height: 13px;
}
.homepage .pricing-section .plans__price-text {
    font-size: 28px;
    line-height: 40px;
}
.homepage .pricing-section .plans__price-text span {
    font-size: 20px;
    line-height: 30px;}
    .homepage .pricing-section .plans__price-desc {
        font-size: 9px;
        line-height: 13px;
    }
.homepage .pricing-section .plans__title-3 {
    font-size: 19px;
    line-height: 28px;
    margin-bottom: 2px;
    
}

}
@media (max-width:768px) {
    .homepage .pricing-section {
        padding-bottom: 0px;
    }
}
@media (max-width: 737px){
.pricing-section .features-page .features ul.feature-list li:after {
    background: #5B5859;
    background: #3e3c3c;
}
}

.pricing-section .features-page .features ul.feature-list .item .content {
    padding: 20px 0px 20px;
}
.pricing-section .features-text{
    margin-bottom: 16px;
}

@media (max-width: 768px){
.homepage .pricing-section .features-text {
    margin-bottom: 13px;
}
.homepage .pricing-section #sign-up-btn{
    margin-bottom: 0px;
}
.homepage .pricing-section .plans__price-box{
    padding: 0px;
}
.homepage .pricing-section .plans__title-3 {
    line-height: 1px;
}
.pricing-section .features-page .features ul.feature-list .item .content {
    padding: 35px 0px 20px;
}


}

@media (max-width:568px){
    .homepage .pricing-section .features-page .features{
        margin-top: -15px;
    }
}

@media (min-width:847px) {
    .pricing-section .features-page .home-container {
        display: none;
    }
    .pricing-section .features-page .home-container-desk{
        display: flex;
        justify-content: center;
        margin-bottom: 12px;
    }
}
@media (max-width:847px) {

    .pricing-section .features-page .home-container-desk{
        display: none;
    }
}






.pricing-section .page-wrapper {
    padding-top: 5%;
    display: flex;
    -webkit-display: flex;
    justify-content: space-around;
  }
  

  .pricing-section .card-wrapper {
    background: white;   
    border-radius: 6px;
    /*max-height: 100px;
    max-height: 225px;
    max-height: 210px;
    max-height: 220px;*/
    overflow: hidden;
    padding: 2rem;
    margin:0px 8px;
    width: 40%;  
    /*transition: max-height 500ms ease-in-out;  */
    transition: 0.5s ease-in;
    background: #272727;
    padding: 2px 5px;

  }
  
  .pricing-section.card-details,
  .pricing-section .reveal-details {
    font-weight: 300;
  }
  
  
  
  .pricing-section .card-height-animate {
    height: 300px; 
    
  }
  /*.pricing-section .card-wrapper:hover {
    max-height: 620px;
  }*/
  .pricing-section .card-wrapper {
    color: white;
  }
  .pricing-section .card-wrapper .reveal-details ul {
    margin-top: 15px;
    list-style: none;
  }
  
  .pricing-section .card-wrapper .reveal-details ul li {
    position: relative;
  }
  .pricing-section .card-wrapper .reveal-details ul li p::before {
    width: 17px;
    height: 13px;
    position: absolute;
    content: "";
    top: -1px;
    left: -25px;
    background-image: url(img/stick.svg);
    background-repeat: no-repeat;
  }
 .homepage .pricing-section .features-text h1{
    margin-top: 0px;
  }
  @media (max-width: 768px) {
    .homepage .features-text {
        margin-top: 18px;
    }
    .homepage .pricing-section .features-text {
        margin-top: 35px;
    }
    .homepage .pricing-section  .pricing-custom p {
        font-size: 15px !important;
        margin: 0!important;
        margin-bottom: 50px !important;
    }
    .features-page .features ul.feature-list .item .desc ul{
        margin-top: 15px;
        margin-bottom: 5px;
    }

  }
  .pricing-section .card-wrapper .card-details{
    margin-top: 15px;
  }
  .pricing-section .card-wrapper .card-details p{
    margin-top: 15px;
  }
  .pricing-section .card-wrapper .plans__price-box b {
    margin-top: 15px;
  }
  .pricing-section .card-wrapper .reveal-details ul {
    margin-bottom: 15px;
  }

  @media (max-width:959px){
    .homepage .pricing-section .features-text br{
        display: none;
    }
  }