
.App {
  text-align: center;
  height: 100vh;
  background: #18181b;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Global code by Shaheryar Abid */
.small_width_page_con .small_width_page_con_inner {
    max-width: 766px;
    margin: 0 auto;
}
.border_radius_normal_btn { border-radius: 4px; }

/*colors*/
.white_bg { background-color: #fff; }
:root {
  --gray_h2_color: #A6ADBF;
  --light_grey_border_color: #E1E7EE;
  --settings_table_th_bg_color: #8892AA;
    --color_success: #29AF61;
    --color_warning: #fcd30a;
    --color_danger: #F73A5D;
    --color_theme_default: #6786DE;
    --color_theme_dark: #253b6e;
    --color_gray_default: #8892AA;
    --color_theme_default_bg_hover: #e8effd;
    --color_cta_buttons_bg_light_gray: #f6f7f9;
    --color_cta_buttons_bg_light_gray_hover: #e1e7ee;
}
.light_gray_border_color { border-color: var(--light_grey_border_color); }
.simple_gray_border { border: 1px solid var(--light_grey_border_color); }
.gray_h2 { color: var(--gray_h2_color); }

.bg_transparent { background-color: transparent; }
.bg_transparent_imp { background-color: transparent !important; }

.icon_color_success { color: var(--color_success); }
.icon_color_warning { color: var(--color_warning); }
.icon_color_danger{ color: var(--color_danger); }
.icon_color_theme_default { color: var(--color_theme_default); }
.color_theme_default_imp { color: var(--color_theme_default) !important; }
.color_theme_default { color: var(--color_theme_default); }
.icon_color_theme_dark { color: var(--color_theme_dark); }
.color_theme_dark_imp { color: var(--color_theme_dark) !important; }
.color_theme_dark { color: var(--color_theme_dark); }
.icon_color_gray_default { color: var(--color_gray_default); }

.theme_dark_bg_image { background-image: var(--theme_dark_blue_color); }
.theme_dark_bg_image_imp { background-image: var(--theme_dark_blue_color) !important; }

.text_align_left { text-align: left; }
.text_align_right { text-align: right; }

.empty_space_50 {
    display: block;
    position: relative;
    width: 100%;
    height: 50px;
}
.h3 {
    font-size: 16px;
    line-height: 22px;
}
.text_center { text-align: center; }
.block { display: block; }

.save_button {
    background-image: var(--theme_dark_blue_color) !important;
    color: var(--white);
}

.success_button {
    background-color: var(--color_success);
    color: var(--white);
}
.success_button:hover,
.success_button:focus,
.success_button:active {}
.uppercase {text-transform: uppercase;}

.danger_para {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    padding: 10px;
    border-radius: 4px;
}

.warning_para {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
    padding: 10px;
    border-radius: 4px;
}

.success_para {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    padding: 10px;
    border-radius: 4px;
}

.relative {
    position: relative;
}

.flex_center {
    display: flex;
    align-items: center;
}

.select {
    color: var(--black);
    border: 1px solid #f2f2f2;
    padding: 7px;
}

.select:active,
.select:focus {
    boox-shadow: 0,2px,4px rgba(0,0,0,0.3);
    outline: 0px;
}

.question_mark {
    display: inline-block;
    margin-left: 8px;
    margin-right: 8px;
    position: relative;
    top: -1px;
}

.grid_tooltip {
    font-weight: 400;
    font-size: 14px;
    text-align: left;
}
.sort_icon_con {
    display: inline;
    width: 20px;
}

/*.search_and_date_filters .search_input {*/
/*    padding: 4px;*/
/*    border: 1px solid var(--light_grey_border_color);*/
/*    border-radius: 4px;*/
/*}*/

.date_container {
    position: relative;
    padding: 5px;
}
.date_container .date_display {
    position: relative;
    font-size: 12px;
    overflow: hidden;
    padding: 5px;
    border: 1px solid var(--light_grey_border_color);
    border-radius: 4px;
    cursor: pointer;
    white-space: nowrap;
    display: flex;
    align-items: center;
    height: 30px;
    color: #d3c7cb;
    
border-color: #5B5859;
}
.date_container .date_display:hover,
.date_container .date_display:focus,
.date_container .date_display:active {
    box-shadow: 0px 2px 4px rgba(0,0,0,0.2);
}
.date_container .date_popup_container {
    position: absolute;
    top: 100%;
    right: 0px;
    z-index: 100000;
    border: 1px solid var(--light_grey_border_color);
    padding: 5px;
    background: #fff;
    box-shadow: 0px 4px 8px rgba(0,0,0,0.4);
    background: #18181b;
    border-color:#5B5859 ;
    color: white;
    
}
.date_container .date_popup_container .rdrDateRangePickerWrapper {}
.date_container .date_popup_container .rdrDateRangePickerWrapper .rdrSelected,
.date_container .date_popup_container .rdrDateRangePickerWrapper .rdrInRange,
.date_container .date_popup_container .rdrDateRangePickerWrapper .rdrStartEdge,
.date_container .date_popup_container .rdrDateRangePickerWrapper .rdrEndEdge {
    top: 3px;
    bottom: 3px;
}
.date_container .date_popup_container .rdrDateRangePickerWrapper .rdrDayNumber { top: 3px; bottom: 3px; }
.date_container .date_popup_container .rdrDateRangePickerWrapper .rdrMonth { width: 21em; }
.date_container .date_popup_container .rdrDateRangePickerWrapper .rdrDay { height: 2em; line-height: 2em; }
.date_container .date_popup_container .rdrDateRangePickerWrapper .rdrWeekDay { line-height: 1.667em; }
.date_container .date_popup_container .rdrDateRangePickerWrapper .rdrMonthName { color: #000; padding: 4px; }
.date_container .date_popup_container .rdrDateRangePickerWrapper .rdrDateDisplay { margin: 0.2rem; }
.date_container .date_popup_container .rdrDateRangePickerWrapper .rdrDateDisplayItem input { height: 1.6em; }
.date_container .date_popup_container .rdrDateRangePickerWrapper .rdrMonthAndYearWrapper {
    height: 50px;
    padding-top: 6px;
}
.date_container .date_popup_container .rdrDateRangePickerWrapper .rdrDefinedRangesWrapper {
    width: 160px;
}
.date_container .date_popup_container .rdrDateRangePickerWrapper .rdrDefinedRangesWrapper .rdrInputRange:last-of-type { display: none; }
.date_container .date_popup_container .rdrDateRangePickerWrapper .rdrDefinedRangesWrapper .rdrInputRange {
    align-items: center;
    padding: 5px 10px;
}
.date_container .date_popup_container .apply_btn {
    padding: 5px 20px;
    cursor: pointer;
    display: inline-block;
    /* float: right; */
    line-height: 20px !important;
    background: var(--color_success);
    color: var(--white);
    border-radius: 4px;
}
.date_container .date_popup_container .apply_btn:hover,
.date_container .date_popup_container .apply_btn:active {
    background: rgba(34, 145, 80,0.8);
}
.date_container .date_display {}

.delete_dialogue .k-dialog .k-dialog-buttongroup .action_bar_buttons,
.update_cost_dialogue .k-dialog .k-dialog-buttongroup .action_bar_buttons {
    display: flex;
    width: 100%;
    padding: 8px;
    justify-content: space-between;
}
.delete_dialogue .k-dialog .k-dialog-titlebar,
.update_cost_dialogue .k-dialog .k-dialog-titlebar {
    background: var(--white);
    color: var(--black);
    border-radius: 4px;
    background-color: #18181bed;
}
.delete_dialogue .k-dialog {
    max-width: 450px;
    width: 450px;
    max-height: 300px;
    height: 300px;
    border-radius: 4px;
}
.update_cost_dialogue .k-dialog {
    max-width: 700px;
    width: 700px;
    min-height: 550px;
    /*height: 300px;*/
    border-radius: 4px;
}

.update_cost_dialogue .date_popup_container .search_and_date_filters .apply_btn {
    background: var(--color_success);
    color: var(--white);
    border-radius: 4px;
    margin-right: 10px;
    font-size: 12px;
    line-height: 29px;
    height: 29px;
}

.update_cost_dialogue .k-dialog-content {
    overflow: visible;
    padding: 30px 25px;
}

.update_cost_dialogue .k-dialog-titlebar {
    box-shadow: 0px 4px 8px rgba(0,0,0,0.1);
}

.full_width {
    width: 100%;
}
.block {
    display: block;
}

.add_affiliate_network_dialogue_content section .note,
.add_affiliate_network_dialogue_content .note {
    margin: 15px 0;
    background-color: #f6f7f9;
    background-color: #29292e;
    color: #fff;
    border-left: 3px solid var(--theme_blue_color);
    padding: 10px;
    display: flex;
    grid-gap: 11px;
}
.add_affiliate_network_dialogue_content section .note .note_p,
.add_affiliate_network_dialogue_content .note  .note_p{
    color: #fff;
}


.k-notification-container {
    margin: 6px;
    z-index: 10000;
}

.k-notification-group .k-notification {
    border-radius: 8px;
}
.k-notification-error .k-notification-wrap, .k-notification-success .k-notification-wrap{
    padding: 15px;
}
.k-loading-mask {
    z-index: 100000 !important;
    background: transparent !important;
    color: white;

}
.k-loading-color{
    opacity: 0.05 !important;

}
.k-loading-mask
.action_buttons_title button, .page_bottom_menu_button .report_link {
    color: var(--theme_dark_blue_color);
}

#daterangepicker {
    /* min-width: 500px; */
}

.date_popup_container .rangecontainer > div {
    margin: 2px 2px 3px;
}
.box_shadow_tt {
    box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
}
.date_container .date_popup_container input {
    background: transparent !important;
    color: white !important;
    border: 1px solid #5B5859;
    outline: #e0358b;
}
.fromDateHourContainer{
    border: 1px solid #5B5859 !important;
}
.fromDateHourContainer select{
    background: transparent !important;
    color: white !important;
    border: 1px solid #5B5859;
    outline: #e0358b;
}
.fromDateHourContainer select option{
    background: black !important;
    color: white !important;
    border: 1px solid #5B5859;
    background: #222 !important;

}
.monthYearContainer select{
    background: transparent !important;
    color: white !important;
    border: 1px solid #5B5859;
    outline: #e0358b;
}
.monthYearContainer select option{
    background: black !important;
    color: white !important;
    border: 1px solid #5B5859;
    background: #222 !important;

}
::-webkit-scrollbar-track{
    background: #18181B;

}

::-webkit-scrollbar-thumb{
    background: rgb(77, 75, 75);
    border-radius: 5px;

}
::-webkit-scrollbar{
    width: 1m;

}
::-webkit-scrollbar-corner{
    background:#18181b;
}
