.section .h2 {
    margin-bottom: 3px;
}
section {
    padding: 15px 0;
}

.buttons_row {
    margin: 20px 0 30px;
}

.buttons_row .edit_button {
    margin-right: 10px;
}

.buttons_row button {
    border-radius: 4px;
    min-width: 100px;
}
.buttons_row .cancel_button{
background: transparent !important;
border: 1px solid #e9e9e9;
}
.buttons_row .cancel_button:hover{
    background: transparent !important;
    color: rgb(207, 198, 198);
    border: 1px solid rgb(207, 198, 198);
    }
.settings-combobox{
    width: 30em;
}
.settings-combobox:focus{
    width: 30em;
}

.k-dialog-titlebar{
    background-color: #2e4887;
}

/* .save-btn-bg-clr {
    background: #2e4887 !important;
    color: white !important;
} */
.white_box_with_header_con .k-dropdown-wrap{
    background: #111;
    color: white;
}
.white_box_with_header_con .k-select{
    background: #111;
}
.white_box_with_header_con .k-select:hover{
    background: #222;
    color: white;
}

@media (max-width:466px) {
.settings-combobox{
    width: 90%;
}
.settings-combobox:focus{
    width: 90%;
}
}
