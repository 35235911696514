
.page_menu_bar {
  display: block;
  width: 100%;
  background: #18181a;
}
.page_menu_bar .page_menu_bar_top,
.page_menu_bar .page_menu_bar_bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--light_grey_border_color);
  border-color: #5B5859;
}
.page_menu_bar_bottom{
  border-bottom: 0px;
}
.page_menu_bar .page_menu_bar_top .grouping_dropdowns > span {
  margin: 0px 10px;
  max-width: 150px;
}
.page_menu_bar .page_menu_bar_top .grouping_dropdowns {
  display: flex;
  grid-gap: 10px;
  margin-left: 15px;
}
.page_menu_bar .page_menu_bar_top .grouping_dropdowns .grouping_tags select {
  padding: 4px;
  border: 1px solid var(--light_grey_border_color);
  background-color: #18181a;
  color: white;
  border-color: #5B5859;
}
.page_menu_bar .page_menu_bar_top .grouping_dropdowns .grouping_tags select:focus{
  border-color: #e0358b;
  transition: 0.5s ease;
}
.page_menu_bar .page_menu_bar_top .grouping_dropdowns .grouping_tags select:hover{
  border-color: #e0358b;
  transition: 0.5s ease;
}
.page_menu_bar .page_menu_bar_top .search_and_date_filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page_menu_bar .page_menu_bar_top .search_and_date_filters > div,
.page_menu_bar .page_menu_bar_top .search_and_date_filters > span {
  margin: 0 4px;
}

.page_menu_bar
  .page_menu_bar_top
  .search_and_date_filters
  > span
  .k-daterangepicker
  .k-daterangepicker-wrap
  .k-textbox-container,
.k-daterangepicker-wrap .k-textbox-container,
.k-floating-label-container {
  max-width: 120px;
  width: 100%;
}
/*.k-daterangepicker-wrap .k-textbox-container > .k-label {*/
/*    display: none;*/
/*}*/
.page_menu_bar .page_menu_bar_top .search_and_date_filters .search_input input {
  /*line-height: 2.33333333em;*/
  padding: 6px 12px;
  background: #18181a;
  color: white;
  border-color: #5B5859;
  outline: none;
}
.page_menu_bar .page_menu_bar_top .search_and_date_filters .search_input input:hover,
.page_menu_bar .page_menu_bar_top .search_and_date_filters .search_input input:focus {
  /*line-height: 2.33333333em;*/
  border-color: #e0358b;
  transition: 0.5s ease;

}
.page_menu_bar .page_menu_bar_top .search_and_date_filters .apply_btn,
.page_menu_bar .page_menu_bar_bottom .right .add_button {
  background: #e0358b;
  color: var(--white);
  border-radius: 4px;
  margin-right: 10px;
  font-size: 12px;
  line-height: 29px;
  height: 29px;
}
.page_menu_bar .page_menu_bar_bottom {
  padding: 7px 5px;
}

.page_menu_bar .page_menu_bar_bottom .left {
  display: flex;
}

.page_menu_bar .page_menu_bar_bottom .right {
  display: flex;
  align-items: center;
}

.action_buttons_title {
  position: relative;
}
.action_buttons_title button,
.page_bottom_menu_button {
  background: transparent;
  color: var(--color_theme_dark);
  color: white;
  border: 0px;
  font-size: 12px !important;
  text-shadow: none;
  line-height: 100%;
  align-items: center;
}
.action_buttons_title button:hover,
.page_bottom_menu_button:hover {
  background: var(--color_cta_buttons_bg_light_gray_hover);
  color: var(--color_theme_dark);
  color: white;
  background: #383838;
}
.action_buttons_popup {
  display: inline-block;
  padding: 2px 0;
}
.action_buttons_popup .popup_link {
  display: flex;
  flex-direction: column;
}
.action_buttons_popup .popup_link .link {
  cursor: pointer;
  padding: 4px 12px;
  border: 0px;
  text-align: left;
  border-bottom: 1px solid var(--light_grey_border_color);
  color: var(--color_theme_dark);
  background: #18181b;
  border-bottom: 1px solid #5B5859;
  color: white;
}

.action_buttons_title button:disabled,
.action_buttons_popup .popup_link .link:disabled,
.page_bottom_menu_button:disabled {
  /*color: var(--color_gray_default);*/
  filter: grayscale(0.1);
  cursor: not-allowed;
  opacity: 0.3;
}
.action_buttons_popup .popup_link .link span.k-icon {
  font-size: 12px !important;
}
.action_buttons_popup .popup_link .link:hover {
  background: var(--color_theme_default_bg_hover);
  background: #2c2c31;
}
.action_buttons_popup .popup_link .link:last-of-type {
  border-bottom: 0px;
}
.k-animation-container {
  width: auto !important;
}

.page_bottom_menu_button:hover {
  background: var(--color_cta_buttons_bg_light_gray_hover);
  background: #383838;
  color: var(--color_theme_dark);
  color: white;
}

/*Add Affiliate Networks Popup*/
.add_affiliate_networks_dialogue .k-dialog {
  max-width: 1024px;
  width: 100%;
  min-height: 76%;
  border-radius: 4px;
}
.add_affiliate_networks_dialogue .k-dialog .k-dialog-titlebar {
  border-radius: 4px 4px 0 0;
  background-color: #fff;
  background-color: #18181bed;
  color: #000;
  color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.add_affiliate_networks_dialogue .con {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.add_affiliate_networks_dialogue .k-dialog .k-dialog-titlebar div {
  font-weight: 600;
}
.add_affiliate_networks_dialogue .k-dialog .k-dialog-content {
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 73vh;
  color: white;
  background: #18181b;
}
.add_affiliate_networks_dialogue .k-dialog .k-dialog-buttongroup {
  display: block;
  background-color: #1e1e20;
}
.add_affiliate_networks_dialogue
  .k-dialog
  .k-dialog-buttongroup
  .action_bar_buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.add_affiliate_networks_dialogue
  .k-dialog
  .k-dialog-buttongroup
  .action_bar_buttons
  .left {
}
.add_affiliate_networks_dialogue
  .k-dialog
  .k-dialog-buttongroup
  .action_bar_buttons
  .right {
}
.add_affiliate_networks_dialogue
  .k-dialog
  .k-dialog-buttongroup
  .action_bar_buttons
  button {
  min-width: 100px;
}
.add_affiliate_networks_dialogue
  .k-dialog
  .k-dialog-content
  .add_affiliate_network_dialogue_content {
  padding: 20px;
  text-align: left;
}
.add_affiliate_networks_dialogue
  .k-dialog
  .k-dialog-content
  .add_affiliate_network_dialogue_content
  .content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 25px;
}

.add_affiliate_networks_dialogue
  .k-dialog
  .k-dialog-content
  .add_affiliate_network_dialogue_content
  .content
  .box {
  min-height: 90px;
  /*border: 1px solid #e1e7ee;*/
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.add_affiliate_networks_dialogue
  .k-dialog
  .k-dialog-content
  .add_affiliate_network_dialogue_content
  .content
  .box:hover {
  border: 0px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
}

.add_affiliate_networks_dialogue .k-dialog .content .box .inner span.image,
.add_affiliate_networks_dialogue .k-dialog .content .box .inner span.text {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
  border:1px solid #e1e7ee;
}
.add_affiliate_networks_dialogue .k-dialog .content .box .inner {
}
.add_affiliate_networks_dialogue .k-dialog .content .box .inner span.image img {
  max-width: 70%;
}
.add_affiliate_network_dialogue_content .form_con .input_group {
  padding-bottom: 15px;
}
.lander_name_con {
}
.add_affiliate_network_dialogue_content .form_con .input_group input,
.add_affiliate_network_dialogue_content .add_affiliate_popup_table input {
  color: var(--black);
  border: 1px solid #c3cfde;
  /*border: 0px;*/
  width: 100%;
  padding: 5px;
  font-size: 12px;
  margin: 5px 0;
  border-radius: 4px;
  background: transparent;
  color: white;
}
.add_affiliate_network_dialogue_content .form_con .input_group input:active,
.add_affiliate_network_dialogue_content .form_con .input_group input:focus,
.add_affiliate_network_dialogue_content .add_affiliate_popup_table input:active,
.add_affiliate_network_dialogue_content .add_affiliate_popup_table input:focus {
  border: 1px solid #ccc;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.26);
  outline: none;
  border-radius: 4px;
}
.add_affiliate_network_dialogue_content .form_con section {
  padding-top: 0px;
}
.add_affiliate_network_dialogue_content .form_con section h3 {
  margin-top: 0px;
  color: white;
}

.add_affiliate_network_dialogue_content .question_mark {
  display: inline-block;
  margin-left: 8px;
  position: relative;
  top: -1px;
}
.add_affiliate_network_dialogue_content label {
  display: block;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #000;
  margin-bottom: 3px;
  color: white !important;
}
.add_affiliate_network_dialogue_content .form_con .five_hun {
  max-width: 500px;
}
.add_affiliate_network_dialogue_content .form_con .five_hun_half {
  max-width: 250px;
}

.add_affiliate_popup_table,
.add_affiliate_popup_table thead tr th span,
.add_affiliate_popup_table tbody tr td span,
.add_affiliate_popup_table .text {
  font-size: 12px;

  color: white;
}

.add_affiliate_popup_table thead tr th,
.add_affiliate_popup_table tbody tr td {
  padding-right: 12px;
}
.add_affiliate_popup_table td .text {
  font-weight: 400;
}
.add_affiliate_popup_table .first_col {
  text-align: right;
  width: 120px;
}
.add_affiliate_popup_table .second_col {
  width: 125px;
}
.add_affiliate_popup_table .last_col {
  width: 100px;
}
.ts_table_con,
.traffic_source_table {
  max-width: 530px;
}
.ts_table .last_col,
.traffic_source_table .last_col {
  width: 120px;
}
.add_affiliate_popup_table .ts_table_var_col {
  padding-right: 0;
}
.add_affiliate_popup_table .ts_table_var_col .text {
  margin-right: 7px;
}
.add_affiliate_popup_table .ts_table_var_col .delete_variable_con {
}
.add_affiliate_popup_table .ts_table_var_col .delete_variable_con span {
  font-size: 16px;
}
.k-switch {
  font-size: 8px !important;
  width: 32px;
  margin-right: 8px;
}
.k-switch .k-switch-container {
  width: 100%;
}
.k-switch .k-switch-container .k-switch-off .k-switch-handle {
  left: 0;
}
.k-switch-on .k-switch-handle {
  left: calc(100% - 18px);
}
.k-switch .k-switch-container .k-switch-handle {
  width: 20px;
  height: 20px;
  top: -4px;
}
.k-switch .k-switch-container .k-switch-label-on,
.k-switch .k-switch-container .k-switch-label-off {
  visibility: hidden;
}
.k-switch .k-switch-container {
  height: 12px;
}
.k-switch {
  overflow: visible;
}

.add_affiliate_network_dialogue_content .input_group_inline_small {
  width: 150px;
  margin-right: 10px;
  display: inline-block;
  margin-bottom: 15px;
}
.add_affiliate_network_dialogue_content .input_group_inline_small label {
  margin-bottom: 5px;
}

.add_affiliate_network_dialogue_content .copy_group {
  background-color: #f6f7f9;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
  padding: 8px 0px !important;
  background: transparent;
}
.add_affiliate_network_dialogue_content .copy_group .tooltip {
  position: absolute;
  left: -24px;
  top: 8px;
  margin-left: 0;
}
.add_affiliate_network_dialogue_content .copy_group .tooltip > span {
  margin-left: 0px;
  font-size: 19px;
}

.add_affiliate_network_dialogue_content .copy_group .input_container {
}
.add_affiliate_network_dialogue_content .copy_group .input_container input {
  width: calc(80% - 10px);
  display: inline-block;
  margin: 0 5px;
  background-color: transparent;
}
.add_affiliate_network_dialogue_content .copy_group .input_container .tags,
.add_affiliate_network_dialogue_content .copy_group .input_container .copyTags {
  width: 20%;
  font-size: 12px;
  line-height: 16px;
  color: var(--white);
  display: inline-block;
}
.add_affiliate_network_dialogue_content .copy_group .input_container .tags span,
.add_affiliate_network_dialogue_content
  .copy_group
  .input_container
  .copyTags
  span {
  margin: 0 0 0 10px;
  background-color: var(--theme_dark_blue_color);
  padding: 6px 6px;
  border-radius: 4px;
  background: #e0358b;
  cursor: pointer;
}
.add_affiliate_network_dialogue_content
  .copy_group
  .input_container
  .tags
  span:hover,
.add_affiliate_network_dialogue_content
  .copy_group
  .input_container
  .copyTags
  span:hover {
  background-color: var(--theme_dark_blue_bg_hover);
  background: #ba2374;
}
.add_affiliate_network_dialogue_content
  .copy_group
  .input_container
  .tags
  span:active,
.add_affiliate_network_dialogue_content
  .copy_group
  .input_container
  .copyTags
  span:active {
  background-color: var(--theme_blue_color);
}
.add_affiliate_network_dialogue_content .currency_group {
  padding-bottom: 0px;
}
.add_affiliate_network_dialogue_content .form_con .switches_row {
  min-height: 27px;
  margin: 8px 0px;
  display: flex;
  grid-gap: 10px;
  align-items: center;
}

.add_affiliate_network_dialogue_content .form_con .additional_settings_con {
  font-size: 12px;
}
.add_affiliate_network_dialogue_content .form_con .additional_settings_con p {
  margin: 5px 0;
  padding: 0px;
}
.add_affiliate_network_dialogue_content
  .form_con
  .switches_row
  .k-switch-label-on,
.add_affiliate_network_dialogue_content
  .form_con
  .switches_row
  .k-switch-label-off {
  display: inline;
  width: calc(100% - 2.2em - 10px);
  position: absolute;
  top: 52%;
}
.add_affiliate_network_dialogue_content
  .form_con
  .switches_row.k-switch-label-off {
  right: 6px;
}
.add_affiliate_network_dialogue_content
  .form_con
  .switches_row
  .k-switch-label-on {
  left: 6px;
}

.ts_switches_row {
  margin: 4px 0;
}
.add_affiliate_network_dialogue_content section .ts_note {
  margin: 0px;
}

.mb-10 {
  margin-bottom: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.mh-0 {
  margin-left: 0;
  margin-right: 0;
}
.ts_postback_input {
  margin: 10px 0 15px 0;
  width: 100%;
  color: var(--black);
  border: 1px solid #c3cfde;
  border-radius: 4px;
  padding: 5px;
}
.ts_postback_input:active,
.ts_postback_input:focus {
  border: 1px solid #ccc;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.26);
  outline: none;
}

.ts_postback_tokens_container {
}
.ts_postback_tokens_container .active,
.ts_postback_tokens_container .token {
  box-sizing: border-box;
  padding: 0 10px;
  height: 32px;
  margin-bottom: 5px;
  margin-right: 5px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.ts_postback_tokens_container .token {
  color: #8892aa;
  background-color: #f0f3f7;
}

.ts_postback_tokens_container .active {
  color: var(--white);
  background-color: var(--theme_blue_color);
}
.empty_space_10 {
  display: block;
  height: 10px;
}
.ts_add_parameter {
  margin: 10px 0 15px 0;
}
.ts_add_parameter p {
  margin: 0px;
  padding: 0;
}
.ts_add_parameter p span {
  margin-right: 2px;
}
.flex_vertical_row {
  display: flex;
  flex-direction: column;
}

.ctas_con {
  margin: 5px 0;
  position: relative;
  width: 170px;
}
.ctas_con input {
  color: var(--black);
  border: 1px solid #c3cfde;
  padding: 5px;
  border-radius: 4px;
  color: white;
}
.ctas_con input:active,
.ctas_con input:focus {
  border: 1px solid #ccc;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.26);
  outline: 0px;
}
.ctas_con .cta_icons_con {
  position: absolute;
  top: 3px;
  right: 4px;
}
.ctas_con .cta_icons_con .cta_icons {
  background-color: var(--color_cta_buttons_bg_light_gray);
  color: #e0358b;
  border-radius: 4px;
  font-size: 18px;
  margin: 2px;
}
.ctas_con .cta_icons_con .cta_icons:hover,
.ctas_con .cta_icons_con .cta_icons:active,
.ctas_con .cta_icons_con .cta_icons:focus {
  background-color: var(--color_cta_buttons_bg_light_gray_hover);
}

.status_verification_con {
  border: 1px solid var(--color_gray_default);
  border-radius: 4px;
  padding: 8px 10px;
  display: flex;
  justify-content: space-between;
}
.status_verification_con .text span {
  display: block;
}

.k-radio:checked {
  border-color: #e0358b;
  color: #ffffff;
  background-color: #e0358b;
}
.k-radio:checked:focus {
  border-color: var(--theme_blue_color);
  box-shadow: 0 0 0 2px rgba(103, 134, 222, 0.3);
}

.grey_bg_box {
  background-color: var(--color_cta_buttons_bg_light_gray);
}
.campaign_flow_diagram {
  display: flex;
  align-items: center;
}
.campaign_flow_diagram .logo_img {
  display: inline-block;
}
.campaign_flow_diagram .logo_img img {
  width: 22px;
}

.light_grey_border_bottom {
  border-bottom: 1px solid var(--light_grey_border_color);
}
.traffics_flow {
  font-size: 10px;
  color: #8892ab;
}

.weightage_con {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.weightage_con select {
  flex: 1;
  padding: 4px;
}
.weightage_con .k-icon {
  cursor: pointer;
}
.weightage_con .weightage_inner_con .k-icon {
  background-color: var(--color_cta_buttons_bg_light_gray);
  cursor: pointer;
  margin: 2px;
  background: transparent;
}
.weightage_con .weightage_inner_con .k-icon:hover,
.weightage_con .weightage_inner_con .k-icon:active {
  background-color: var(--color_cta_buttons_bg_light_gray_hover);
}

.weightage_con .weightage_inner_con {
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  padding: 2px;
  min-width: 75px;
}
.weightage_con .percentage {
  min-width: 58px;
  text-align: right;
}

.search_and_date_filters {
}
.search_and_date_filters .search_input {
  display: flex;
  align-items: center;
}
.search_and_date_filters .search_input .search_type_selection {
}
.search_and_date_filters .search_input .search_type_selection span {
  padding: 5px;
  border-radius: 0px;
  border: 1px solid var(--color_theme_default);
  text-transform: uppercase;
  font-weight: 500;
  color: var(--color_theme_default);
  cursor: pointer;
  position: relative;
  top: -1px;

}
.search_and_date_filters .search_input .k-multiselect,
.search_and_date_filters .search_input input {
  width: 200px;
  display: inline-block;
}

.five_hun_half_con {
  display: flex;
  justify-content: space-between;
  max-width: 500px;
  grid-gap: 15px;
  align-items: center;
}
.five_hun_half_con .five_hun_half {
  width: 100%;
}
.five_hun_half_con .five_hun_half .inner .weightage_inner_con {
  position: relative;
}
.five_hun_half_con .five_hun_half .inner {
  display: flex;
  align-items: center;
  grid-gap: 10px;
}
.five_hun_half_con .five_hun_half .inner .weightage_inner_con .input_con {
}
.five_hun_half_con .five_hun_half .inner .weightage_inner_con .input_con input {
  height: 32px;
  border: 1px solid #f2f2f2;
}
.five_hun_half_con .five_hun_half .inner .weightage_inner_con .numbers_con {
  position: absolute;
  top: 50%;
  right: 2px;
  padding: 6px;
  background: #fff;
  background: transparent;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
.five_hun_half_con .five_hun_half .weightage_inner_con .numbers_con span {
}

.pagination {
  padding: 5px;
  display: inline;
  align-items: center;
  border-radius: 3px;
}
.show_data_number_dropdown {
  display: inline;
  margin: 0 5px;
}
.show_data_number_dropdown select {
  border: 1px solid var(--light_grey_border_color);
  height: 24px;
  cursor: pointer;
  background-color: #18181a;
  color: white;
  border-color: #5B5859;

}

.show_data_number_dropdown select:focus,
.show_data_number_dropdown select:hover {
  outline: none;
  border-color: #e0358b;
  transition: 0.5s ease;
}
/*.pagination:hover {*/
/*    background: var(--color_cta_buttons_bg_light_gray_hover);*/
/*    color: var(--color_theme_dark);*/
/*}*/
.pagination .number {
  padding: 3px;
  background: var(--color_cta_buttons_bg_light_gray_hover);
  background: #2c2c31;
  border-radius: 2px;
}
.pagination span {
  cursor: pointer;
  color: white;
}
.pagination .k-icon {
  margin: 0 4px;
}

.k-loading-mask {
  z-index: 100000;
}

button {
  cursor: pointer;
}

.add_tags div span input {
  min-width: 200px;
  margin: 2px !important;
}

.name_con_mix {
  display: flex;
  border: 1px solid #c3cfde;
  padding: 2px 7px;
  border-radius: 4px;
  align-items: center;
}
.name_con_mix span {
}
.name_con_mix input {
  flex: 1;
  margin: 0px !important;
  border: 0px !important;
}
.name_con_mix input:focus,
.name_con_mix input:active {
  box-shadow: none !important;
}

.k-notification-warning {
  padding: 20px !important;
}

#menuBackDrop {
  width: 100vw;
  height: 100vh;
  display: block;
  background: rgba(0, 0, 0, 0.2);
  /* z-index: 10002; */
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
}

/*==============================================================*/
/*              Temporary css data for pages tables             */
/*==============================================================*/
/*.temporary_affiliate_network_page_data .inner {*/
/*    max-width: 768px;*/
/*    margin: 0 auto;*/
/*    padding: 50px 20px;*/
/*    text-align: left;*/
/*    background-color: var(--white);*/
/*}*/
/*.temporary_affiliate_network_page_data .inner h3 {}*/
/*.temporary_affiliate_network_page_data .inner .settings_table_con .settings_table {*/
/*    width: 100%;*/
/*    border-spacing: 0px;*/
/*    border-width: 1px 0px 1px 1px;*/
/*    border-style: solid;*/
/*    border-color: #A6ADBF;*/
/*}*/
/*.temporary_affiliate_network_page_data .inner .settings_table_con .settings_table tr {}*/
/*.temporary_affiliate_network_page_data .inner .settings_table_con .settings_table tr:nth-child(even){*/
/*    background-color: #f6f7f9;*/
/*}*/
/*.temporary_affiliate_network_page_data .inner .settings_table_con .settings_table tr th {*/
/*    background-color: var(--settings_table_th_bg_color);*/
/*    color: var(--white);*/
/*    font-weight: 400;*/
/*}*/
/*.temporary_affiliate_network_page_data .inner .settings_table_con .settings_table tr th,*/
/*.temporary_affiliate_network_page_data .inner .settings_table_con .settings_table tr td {*/
/*    padding: 0.6em;*/
/*    border-width: 0px 1px 0px 0px;*/
/*    border-style: solid;*/
/*    border-color: #A6ADBF;*/
/*    font-size: 12px;*/
/*    line-height: 15px;*/
/*}*/
/*.temporary_affiliate_network_page_data .inner .settings_table_con .settings_table tr td .icons span {*/
/*    margin: 0 5px;*/
/*}*/
/*.temporary_affiliate_network_page_data .inner .settings_table_con .settings_table tr td {*/
/*    cursor: pointer;*/
/*}*/
/*.temporary_affiliate_network_page_data .inner .settings_table_con .settings_table tr:hover {*/
/*    background-color: #E8EFFD;*/
/*}*/

/* .custom-search-items{
    margin: 0;
    box-sizing: border-box;
    height: 18.33333333em;
    display: flex;
    flex: 1;
    flex-direction: column;
    border-width: 1px;
    margin-bottom: 1em;
    border-radius: 0.41666667em;
    border-style: solid;
    border-color: #C3CFDE;
    overflow: hidden;
    height: 100%;
    padding: 0;
    max-width: 220px;
    margin-right: 0.66666667em;
    
} */
.custom-search-items {
  padding-top: 0 !important;
}
.custom-search-items Input {
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 5px;
  padding-left: 30px;
  border-width: 1px;
  height: 2.33333333em;
  border-radius: 0.25em;
  border-style: solid;
  border-color: #c3cfde;
  background-color: #ffffff;
  box-sizing: border-box;
  margin: 0.66666667em;
  margin-left: 0;
  width: 50%;
  background: transparent;
}

.custom-search-items Input:focus {
  border-color: #6786de;

}

.custom-search-items .k-i-search {
  position: absolute;
  padding-left: 15px;
  color: #a6adbf;
}

.custom-search-items .custom-search-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.campaigns_landers_heading {
  justify-content: space-between;
}
.campaigns_landers_heading div h3 {
  margin: 0;
}
.campaigns_landers_heading div span#add_lander,
.campaigns_landers_heading div span#add_offer {
  cursor: pointer;
  color: #e0358b;
}
#add_lander .k-icon{
  color: #e0358b;
}
#add_offer .k-icon{
  color: #e0358b;
}
#c_m_landers_con {
}
#c_m_landers_con ol {
  margin: 0;
  padding: 10px 5px 10px 20px;
}
#c_m_landers_con ol li .li_inner {
  justify-content: space-between;
}
#c_m_landers_con ol li select {
  width: 100%;
  margin-right: 20px;
  border: 0px;
  padding: 4px;
}
#c_m_landers_con ol li select:hover,
#c_m_landers_con ol li select:focus {
  background-color: var(--theme_blue_light_color);
  outline: 0px;
}

.lan_off_weightage {
  border: 0px;
  min-width: 35px;
  width: 30px;
  padding: 0px;
  margin: 0px;
}
.lan_off_weightage:active,
.lan_off_weightage:focus-visible,
.lan_off_weightage:hover {
  border: 0px;
  min-width: 35px;
  width: 30px;
  padding: 0px;
  margin: 0px;
  outline: none;
}
.lan_off_weightage::-webkit-inner-spin-button,
.lan_off_weightage::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*#c_m_landers_con ol li .actions_con {}*/
/*#c_m_landers_con ol li .actions_con .action {}*/
/*#c_m_landers_con ol li .actions_con .percentage {}*/
/*==============================================================*/
/*              Temporary css data for pages tables             */
/*==============================================================*/
.show_data_number_dropdown select option{
  color: white;
}

.page_menu_bar .page_menu_bar_top .grouping_dropdowns .grouping_tags select option {
  color: white;
}
.add_affiliate_network_dialogue_content .grey_bg_box{
  background-color: #202024;
}
.add_affiliate_network_dialogue_content .grey_bg_box .traffics_flow{
  color: #e0358b;
}
.add_affiliate_network_dialogue_content .form_con section h5 {
  color: white;
}
@media only screen and (max-width: 997px) {
  .action_buttons_title button,
  .page_bottom_menu_button {
    font-size: 9px !important;
  }
}
.action_bar_buttons .left button{
  background: transparent !important;
border: 1px solid white;
}
.action_bar_buttons .left button:hover{
  background: transparent !important;
  color: rgb(207, 198, 198);
border: 1px solid rgb(207, 198, 198);
}
.action_bar_buttons .right button{
background-color: #e0358b !important;
border: 1px solid #e0358b;
}
.action_bar_buttons .right button:hover{
  background-color: #ba2374!important;
  border: 1px solid #ba2374;
  }
  .settings_area_container .k-animation-container{
    background-color: #202024;
    color: white;
  }
.k-animation-container .k-popup{
    color: white;
  }
  .settings_area_container .k-grid-content {
    overflow: auto;
  }
  @media (max-width:1130px){
    .App {
      height: min-content;
    }
    .page_container{
      /*height: min-content;*/
    }
    .page_menu_bar .page_menu_bar_top,
  .page_menu_bar .page_menu_bar_bottom 
  {
    margin-top: 5px;
    flex-wrap: wrap;
    align-items: center;

  }
  .grouping_dropdowns,.search_and_date_filters,.page_menu_bar .page_menu_bar_bottom .left,.page_menu_bar .page_menu_bar_bottom .right{
    flex-wrap: wrap;
  }
  }
  @media (max-width:390px){
  .top_menu_bar .top_menu_left a .logo_container img{
    width: 80px !important;

  }
  .top_menu_bar .top_menu_left a .logo_container{
    padding: 0px;
  }
}
.k-button.k-button-icon.btn-clr .k-icon.k-i-k-icon{
  color: #e0358b;
}

.k-window-content.k-dialog-content .k-switch-on .k-switch-container{
  background-color: #e0358b;
}