.header_menu_bar {
    background: #18181a;
    padding: 8px 20px 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid lightgray;
    border-color: #5B5859;
}
.header_menu_bar .menu_button {
    color: #8892AA;
    color: #999999;
    background: transparent;
    border: 0px;
    margin-right: 20px;
}
.header_menu_bar .menu_button:hover,
.header_menu_bar .activeButton {
    background: transparent !important;
    color: #FFFFFF;
}