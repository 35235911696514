.features-page .container-1 {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }
  
  .features-page .container-1:after,.features-page .container-1:before{
    display: table;
    content: " ";
  }
  .features-page .container-1:after{
    clear: both;
  }
  
  
  @media (min-width: 768px) {
    .features-page .container-1 {
      width: 750px; } }
  
  @media (min-width: 992px) {
    .features-page .container-1 {
      width: 970px; } }
  
  @media (min-width: 1200px) {
    .features-page .container-1 {
      width: 1170px; } }
  @media (max-width: 737px) {
      .features-page .container-1 {
       padding: 0 30px; } }

.features-page .section.features-section {
   padding-bottom: 0; 
}
@media (max-width: 1281px) {
  .features-page .section {
    padding-top: 55px; } }
@media (max-width: 1025px) {
  .features-page .section {
    padding-top: 45px; } }
@media (max-width: 568px) {
  .features-page .section {
    padding-top: 35px; } }
    .features-page .section.intro-section {
  padding-top: 140px; }
  @media (max-width: 1281px) {
    .features-page .section.intro-section {
      padding-top: 100px; } }
  @media (max-width: 1025px) {
    .features-page .section.intro-section {
      padding-top: 70px; } }
  @media (max-width: 568px) {
    .features-page .section.intro-section {
      padding-top: 50px; } }
      .features-page .section.features-section {
  padding-bottom: 0; }


  .features-page .section {
    padding-top: 55px;
    padding-bottom: 55px; }
    @media (max-width: 1281px) {
      .features-page  .section {
        padding-top: 35px;
        padding-bottom: 35px; } }
    @media (max-width: 1025px) {
      .features-page  .section {
        padding-top: 35px;
        padding-bottom: 35px; } }


        
        .features-page .features {
          margin-top: -50px;
          padding-bottom: 60px; }
          @media (max-width: 1025px) {
            .features-page  .features {
              margin-top: -40px;
              padding-bottom: 0; } }
          @media (max-width: 993px) {
            .features-page  .features {
              max-width: 550px;
              margin: -40px auto 0;
              padding-bottom: inherit; } }
          @media (max-width: 568px) {
            .features-page  .features {
              padding-top: 75px !important;
              padding-top: 40px !important;
              padding-bottom: 0; } }
              .features-page  .features ul.feature-list {
               margin-bottom: -10px; 
               margin-left: -15px;
               margin-right: -15px;
               padding-left: 0;
          }
            @media (max-width: 1025px) {
              .features-page  .features ul.feature-list {
                margin-bottom: -30px; } }
            @media (max-width: 993px) {
              .features-page  .features ul.feature-list {
                margin-bottom: 25px; } }
                .features-page  .features ul.feature-list li {
              text-align: center;
              margin-bottom: 10px;
              height: 204px;
              -webkit-transition: .35s ease height;
              transition: .35s ease height; }
              @media (max-width: 993px) {
                .features-page  .features ul.feature-list li {
                  height: 140px; } }
                  .features-page  .features ul.feature-list li.active-box .item .content {
                z-index: 2;
                -webkit-transform: translateY(-18px);
                        transform: translateY(-18px); }
                        .features-page  .features ul.feature-list li.active-box .item .content:before {
                  opacity: 1; }
                  .features-page  .features ul.feature-list li.active-box .item .content:after {
                  visibility: visible; }
                  .features-page  .features ul.feature-list li.active-box .item .desc {
                opacity: 1;
              }
                .features-page  .features ul.feature-list li.active-box .item .icon {
                background-position-y: -110px !important; }
              @media (max-width: 737px) {
                .features-page  .features ul.feature-list li {
                  height: 110px;
                  max-width: 430px;
                  margin: auto; }
                  .features-page  .features ul.feature-list li:after {
                    content: '';
                    height: 1px;
                    /*bottom: -1px;
                    
                    /*bottom: -42px;*/
                    top:-1px;
                    position: absolute;
                    width: calc( 100% - 30px);
                    left: 15px;
                    background: whitesmoke;
                    background: #161515;
                  }
                    .features-page  .features ul.feature-list li.active-box {
                    height: 270px; }
                    .features-page   .features ul.feature-list li.active-box .item .content {
                      -webkit-transform: translateY(0);
                              transform: translateY(0); } }
                              .features-page  .features ul.feature-list .item {
              -webkit-tap-highlight-color: transparent;
              cursor: pointer;
              display: table;
              height: 100%;
              width: 100%;
              position: relative; }
              .features-page  .features ul.feature-list .item .content {
                height: 100%;
                -webkit-transform: translateY(10px);
                        transform: translateY(10px);
                position: relative;
                padding: 40px 40px 65px;
                -webkit-transition: .4s all ease;
                transition: .4s all ease; }
                @media (max-width: 1025px) {
                  .features-page  .features ul.feature-list .item .content {
                    padding: 20px 15px 40px; }
                    .features-page    .features ul.feature-list .item .content:after {
                      font-family: 'themify';
                      content: "\e646";
                      content: "X";
                      font-family: sans-serif;
                      position: absolute;
                      top: 20px;
                      right: 10px;
                      font-size: 10px;
                      opacity: .4;
                      visibility: hidden; } }
                @media (max-width: 737px) {
                  .features-page   .features ul.feature-list .item .content {
                    padding-top: 0;
                    padding-bottom: 0;
                    padding-left: 20px;
                    padding-right: 20px;
                    -webkit-transform: translateY(0);
                            transform: translateY(0); }
                            .features-page  .features ul.feature-list .item .content:after {
                      right: 20px; } }
                      .features-page  .features ul.feature-list .item .content:before {
                  content: "";
                  background: #ffffff;
                  
                  background: #272727;
                  width: calc(100% + 30px);
                  height: 100%;
                  position: absolute;
                  left: -15px;
                  top: 0;
                  z-index: -1;
                  /*box-shadow: 0 10px 36px 6px #ECF1FD;*/
                  -webkit-transition: .4s all ease;
                  transition: .4s all ease;
                  opacity: 0; }
                  @media (max-width: 568px) {
                    .features-page   .features ul.feature-list .item .content:before {
                      width: 100%;
                      left: 0; } }
                      .features-page .features ul.feature-list .item .title {
                color: #00264D;
                font-size: 22px;
                font-weight: 400;
                color: white;
                color: #ebebeb;
              }
                @media (max-width: 1025px) {
                  .features-page .features ul.feature-list .item .title {
                    font-size: 19px; } }
                @media (max-width: 773px) {
                  .features-page .features ul.feature-list .item .title {
                    font-size: initial; } }
                    .features-page .features ul.feature-list .item .desc {
                margin-top: 10px;
                opacity: 0;
                font-size: 15px;
                -webkit-transition: .3s opacity ease-out;
                transition: .3s opacity ease-out; }
                @media (max-width: 1025px) {
                  .features-page .features ul.feature-list .item .desc {
                    font-size: 14px; } }
                    .features-page .features ul.feature-list .item .icon {
                height: 70px;
                width: 110px;
                margin: 0 auto 15px;
                background-repeat: no-repeat;
                background-image: url("pink to pink.png");
                -webkit-transition: .4s ease background-position-y;
                transition: .4s ease background-position-y; }
                @media (max-width: 1025px) {
                  .features-page  .features ul.feature-list .item .icon {
                    -webkit-transform: scale(0.8);
                            transform: scale(0.8);
                    margin: 0 auto 5px; } }
                @media (max-width: 993px) {
                  .features-page  .features ul.feature-list .item .icon {
                    -webkit-transform: scale(0.7);
                            transform: scale(0.7); } }
                @media (max-width: 773px) {
                  .features-page   .features ul.feature-list .item .icon {
                    -webkit-transform: scale(0.5);
                            transform: scale(0.5); } }
                            .features-page .features ul.feature-list .item .icon.redirects {
                  background-position: 25px 0px;
                  /*background-image: url("img/3.png") ;
                  background-position: 20px 0px;
                  background-size: contain;*/
                }
                  .features-page .features ul.feature-list .item .icon.scaling {
                  background-position: -85px 0px; 
                  /*background-image: url("img/4.png") ;
                  background-position: 20px 0px;
                  background-size: contain;*/
                }
                  .features-page .features ul.feature-list .item .icon.integrate {
                  /*background-position: -195px 0px;*/ 
                
                }
                  .features-page   .features ul.feature-list .item .icon.domains {
                  background-position: -305px 0px; 
                  /*background-image: url("img/5.png") ;
                  background-position: 20px 0px;
                  background-size: contain;*/
                }
                  .features-page   .features ul.feature-list .item .icon.world {
                  background-position: -410px 0px; 
                  /*background-image: url("img/6.png") ;
                  background-position: 20px 0px;
                  background-size: contain;*/
                }
                  .features-page   .features ul.feature-list .item .icon.reporting {
                  /*background-position: -520px 0px;*/ 
                    
                }
                  .features-page    .features ul.feature-list .item .icon.pixel {
                  background-position: -630px 0px; 
                  /*background-image: url("img/7.png") ;
                  background-position: 20px 0px;
                  background-size: contain;*/
                }
                  .features-page     .features ul.feature-list .item .icon.funnel {
                  background-position: -740px 0px;
                  /*background-image: url("img/8.png") ;
                  background-position: 20px 0px;
                  background-size: contain;*/
                }
                  .features-page  .features ul.feature-list .item .icon.access {
                  background-position: -845px 0px;
                  /*background-image: url("img/9.png") ;
                  background-position: 20px 0px;
                  background-size: contain;*/
                }
                  .features-page  .features ul.feature-list .item .icon.ai {
                    
                background-position: -955px 0px;
                  /*background-image: url("img/1.png") ;
                  background-position: 20px 0px;
                  background-size: contain;*/
                }
                  .features-page  .features ul.feature-list .item .icon.bot {
                  background-position: -1055px 0px; 
                  /*background-image: url("img/2.png") ;
                  background-position: 20px 0px;
                  background-size: contain;*/
                }

.features-page .site-content {
  background: white;

  background: #000;
}
.features-page .site-content{
  /*margin-top: 90px;*/
  /*padding-top: 20px;*/


}
.features-page .feature-list {
  list-style: none;
}

.features-page .features-section * {
  font-family: "Nunito Sans", sans-serif;
  letter-spacing: 0.89px;
  line-height: 1.42857143;
  font-weight: 200;
  color: #272727;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  color: white;
}  
.features-page .site-content{
    /*height: 800px;
    height: 1057px;
    height: 1147px;*/
  }
@media (min-width:1024px){

}
/*@media (max-width:991px){
  .features-page .site-content{
    height: 825px;
    height: 1170px;
  }
}
@media (max-width:767px){
  .features-page .site-content{
    height: 1400px;
    height: 1762px;
  }
}
@media (max-width:737px){
  .features-page .site-content{
    height: 1250px;
    height:1538px;
  }
}
@media (width:768px){
  .features-page .site-content{
    height:1311px;
  }
}*/

@media (max-width:569px){
  .features-page .section.features-section{
    padding: 0px;
  }
}



.homepage .features-text h1 {
  text-align: center;
  font-size: 56px;
}
.homepage .features-text p{
  text-align: center;
  padding: 0;
  line-height: 1.2;
  font-size: 26px;
}
.homepage .features-text *{
  color: white;
  font-family: 'Roboto', sans-serif;
}

@media (min-width:769px){
  .homepage .features-text {
    width: 70%;
  }
}

@media (max-width: 1769px){
.homepage .features-text h1 {
  font-size: 56px;
  
}
.homepage .features-text p{
  font-size: 26px;
  padding-left: 10px;
}


}

@media (max-width: 1440px) {
  .homepage .features-text h1{
    font-size: 40px;}
    .homepage .features-text p{
      font-size: 18px;
    }
  
  }


@media (max-width: 1024px){
  .homepage .features-text h1 {
    font-size: 30px;
}
.homepage .features-text p{
  font-size: 15px;

}

}
@media (max-width: 768px) {
  .homepage .features-text h1{
    font-weight: bolder;
    font-size: 47px;}
    .homepage .features-text p{
      margin: 0px;
      font-size: 19px;
    
    }
    .homepage .features-text{
      padding: 54px;
      }
  
  }

  @media (max-width: 690px) {
  .homepage .features-text h1 {
    font-size: 40px;
}
.homepage .features-text p{
  font-size: 17px;
}
.homepage .features-text{
padding: 0px 10px;
  }


}

@media (max-width: 540px) {
  .homepage .features-text h1 {
    font-size: 33px;
}
.homepage .features-text p{
  font-size: 16px;

}
}
@media (max-width: 425px) {
  .homepage .features-text h1 {
    font-size: 29px;
    margin-bottom: 10px;
}
.homepage .features-text p{
  font-size: 12px;
  font-size: 16px;
  line-height: 1.6;
}
}
@media (max-width: 375px) {
  .homepage .features-text h1 {
    font-size: 25px;
    margin-bottom: 10px;
}
.homepage .features-text p{
  font-size: 15px;

}
}

.features-page .features ul.feature-list .item .content{
  padding: 40px 0px 20px;
}

.features-page .features ul.feature-list .item .desc {
  text-align: left;

  margin-top: 5px;

  font-size: 12px;
}
.features-page .features ul.feature-list .item .desc p {
  margin-bottom: 5px;
  line-height: 1.1;
  font-size: 12px;
}
.features-page .features ul.feature-list .item .desc span{
  text-decoration: underline;
  font-weight: bolder;
  line-height: 1.1;
}
.features-page .features ul.feature-list .item .desc ul{
  text-align: left;
  list-style: disc;
  line-height: 1.1;
  padding-left:25px ;
}
.features-page .features ul.feature-list .item .desc ul li{
  text-align: left;
  height: min-content;
  margin-bottom: 2px;
  line-height: 1.1;

}
.features-page .features ul.feature-list .item .desc strong {
  font-weight: bolder;
  font-size: 14px;
}
@media (max-width:568px){
  .features-page .features ul.feature-list .item .desc {
    padding: 0px 5px;
  }
}
@media(max-width:768px){
  .features-page .features ul.feature-list .item .content{
    padding-top: 0px;
    
  }
  .homepage .features-section{
    padding-bottom: 0px ;
  }
  .homepage .features-text{
    padding-bottom: 0px;
    margin-bottom: 25px;
    margin-bottom: 45px;
  }
}