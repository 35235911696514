.h1_con {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
}

.h1_con .yellow_btn {
    background-color: #fcd30a;
    cursor: pointer;
    line-height: 2.33em;
}

.header .learn_more_btn {
    background-color: transparent;
    background-image: none;
    border: 0px;
}

.legacy_domains_row {
    margin-bottom: 10px;
}
.legacy_domains_row .legacy_domains_number {
    display: inline-block;
    width: 20px;
    text-align: left;
    margin: 0;
}
.legacy_domains_row input {
    width: calc(100% - 20px);
}
.legacy_domains_row input:focus {
    border-color: rgba(0, 0, 0, 0.1);
    color: var(--black);
    border-color: var(--theme_blue_color);
}

.cname_img {
    padding: 15px 0;
}
.cname_img img {
    max-width: 100%;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.16);
    width: 100%;
    box-sizing: border-box;
}
